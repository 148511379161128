const emojis = [
    { value: ':grinning:', label: ':grinning:', image: '', unicode: '😀' },
    { value: ':smile:', label: ':smile:', image: '', unicode: '😄' },
    { value: ':grin:', label: ':grin:', image: '', unicode: '😁' },
    { value: ':laughing:', label: ':laughing:', image: '', unicode: '😆' },
    { value: ':satisfied:', label: ':satisfied:', image: '', unicode: '😆' },
    { value: ':sweat_smile:', label: ':sweat_smile:', image: '', unicode: '😅' },
    { value: ':rofl:', label: ':rofl:', image: '', unicode: '🤣' },
    { value: ':joy:', label: ':joy:', image: '', unicode: '😂' },
    { value: ':slightly_smiling_face:', label: ':slightly_smiling_face:', image: '', unicode: '🙂' },
    { value: ':upside_down_face:', label: ':upside_down_face:', image: '', unicode: '🙃' },
    { value: ':wink:', label: ':wink:', image: '', unicode: '😉' },
    { value: ':blush:', label: ':blush:', image: '', unicode: '😊' },
    { value: ':innocent:', label: ':innocent:', image: '', unicode: '😇' },
    { value: ':smiling_face_with_3_hearts:', label: ':smiling_face_with_3_hearts:', image: '', unicode: '🥰' },
    { value: ':heart_eyes:', label: ':heart_eyes:', image: '', unicode: '😍' },
    { value: ':star_struck:', label: ':star_struck:', image: '', unicode: '🤩' },
    { value: ':kissing_heart:', label: ':kissing_heart:', image: '', unicode: '😘' },
    { value: ':kissing:', label: ':kissing:', image: '', unicode: '😗' },
    { value: ':relaxed:', label: ':relaxed:', image: '', unicode: '☺️' },
    { value: ':kissing_closed_eyes:', label: ':kissing_closed_eyes:', image: '', unicode: '😚' },
    { value: ':kissing_smiling_eyes:', label: ':kissing_smiling_eyes:', image: '', unicode: '😙' },
    { value: ':smiling_face_with_tear:', label: ':smiling_face_with_tear:', image: '', unicode: '🥲' },
    { value: ':yum:', label: ':yum:', image: '', unicode: '😋' },
    { value: ':stuck_out_tongue:', label: ':stuck_out_tongue:', image: '', unicode: '😛' },
    { value: ':stuck_out_tongue_winking_eye:', label: ':stuck_out_tongue_winking_eye:', image: '', unicode: '😜' },
    { value: ':zany_face:', label: ':zany_face:', image: '', unicode: '🤪' },
    { value: ':stuck_out_tongue_closed_eyes:', label: ':stuck_out_tongue_closed_eyes:', image: '', unicode: '😝' },
    { value: ':money_mouth_face:', label: ':money_mouth_face:', image: '', unicode: '🤑' },
    { value: ':hugging:', label: ':hugging:', image: '', unicode: '🤗' },
    { value: ':hugging_face:', label: ':hugging_face:', image: '', unicode: '🤗' },
    { value: ':face_with_hand_over_mouth:', label: ':face_with_hand_over_mouth:', image: '', unicode: '🤭' },
    { value: ':thinking:', label: ':thinking:', image: '', unicode: '🤔' },
    { value: ':zipper_mouth_face:', label: ':zipper_mouth_face:', image: '', unicode: '🤐' },
    { value: ':face_with_raised_eyebrow:', label: ':face_with_raised_eyebrow:', image: '', unicode: '🤨' },
    { value: ':neutral_face:', label: ':neutral_face:', image: '', unicode: '😐' },
    { value: ':expressionless:', label: ':expressionless:', image: '', unicode: '😑' },
    { value: ':no_mouth:', label: ':no_mouth:', image: '', unicode: '😶' },
    { value: ':face_in_clouds:', label: ':face_in_clouds:', image: '', unicode: '😶‍🌫️' },
    { value: ':smirk:', label: ':smirk:', image: '', unicode: '😏' },
    { value: ':unamused:', label: ':unamused:', image: '', unicode: '😒' },
    { value: ':rolling_eyes:', label: ':rolling_eyes:', image: '', unicode: '🙄' },
    { value: ':face_with_rolling_eyes:', label: ':face_with_rolling_eyes:', image: '', unicode: '🙄' },
    { value: ':grimacing:', label: ':grimacing:', image: '', unicode: '😬' },
    { value: ':face_exhaling:', label: ':face_exhaling:', image: '', unicode: '😮‍💨' },
    { value: ':lying_face:', label: ':lying_face:', image: '', unicode: '🤥' },
    { value: ':liar:', label: ':liar:', image: '', unicode: '🤥' },
    { value: ':relieved:', label: ':relieved:', image: '', unicode: '😌' },
    { value: ':pensive:', label: ':pensive:', image: '', unicode: '😔' },
    { value: ':sleepy:', label: ':sleepy:', image: '', unicode: '😪' },
    { value: ':drooling_face:', label: ':drooling_face:', image: '', unicode: '🤤' },
    { value: ':drool:', label: ':drool:', image: '', unicode: '🤤' },
    { value: ':sleeping:', label: ':sleeping:', image: '', unicode: '😴' },
    { value: ':mask:', label: ':mask:', image: '', unicode: '😷' },
    { value: ':head_bandage:', label: ':head_bandage:', image: '', unicode: '🤕' },
    { value: ':face_with_head_bandage:', label: ':face_with_head_bandage:', image: '', unicode: '🤕' },
    { value: ':face_vomiting:', label: ':face_vomiting:', image: '', unicode: '🤮' },
    { value: ':hot_face:', label: ':hot_face:', image: '', unicode: '🥵' },
    { value: ':woozy_face:', label: ':woozy_face:', image: '', unicode: '🥴' },
    { value: ':face_with_spiral_eyes:', label: ':face_with_spiral_eyes:', image: '', unicode: '😵‍💫' },
    { value: ':thermometer_face:', label: ':thermometer_face:', image: '', unicode: '🤒' },
    { value: ':face_with_thermometer:', label: ':face_with_thermometer:', image: '', unicode: '🤒' },
    { value: ':nauseated:', label: ':nauseated:', image: '', unicode: '🤢' },
    { value: ':sick:', label: ':sick:', image: '', unicode: '🤢' },
    { value: ':sneezing_face:', label: ':sneezing_face:', image: '', unicode: '🤧' },
    { value: ':sneeze:', label: ':sneeze:', image: '', unicode: '🤧' },
    { value: ':cold_face:', label: ':cold_face:', image: '', unicode: '🥶' },
    { value: ':dizzy_face:', label: ':dizzy_face:', image: '', unicode: '😵' },
    { value: ':exploding_head:', label: ':exploding_head:', image: '', unicode: '🤯' },
    { value: ':cowboy:', label: ':cowboy:', image: '', unicode: '🤠' },
    { value: ':face_with_cowboy_hat:', label: ':face_with_cowboy_hat:', image: '', unicode: '🤠' },
    { value: ':face_with_monocle:', label: ':face_with_monocle:', image: '', unicode: '🧐' },
    { value: ':nerd:', label: ':nerd:', image: '', unicode: '🤓' },
    { value: ':nerd_face:', label: ':nerd_face:', image: '', unicode: '🤓' },
    { value: ':confused:', label: ':confused:', image: '', unicode: '😕' },
    { value: ':slightly_frowning_face:', label: ':slightly_frowning_face:', image: '', unicode: '🙁' },
    { value: ':slight_frown:', label: ':slight_frown:', image: '', unicode: '🙁' },
    { value: ':open_mouth:', label: ':open_mouth:', image: '', unicode: '😮' },
    { value: ':astonished:', label: ':astonished:', image: '', unicode: '😲' },
    { value: ':pleading_face:', label: ':pleading_face:', image: '', unicode: '🥺' },
    { value: ':anguished:', label: ':anguished:', image: '', unicode: '😧' },
    { value: ':cold_sweat:', label: ':cold_sweat:', image: '', unicode: '😰' },
    { value: ':cry:', label: ':cry:', image: '', unicode: '😢' },
    { value: ':scream:', label: ':scream:', image: '', unicode: '😱' },
    { value: ':persevere:', label: ':persevere:', image: '', unicode: '😣' },
    { value: ':sweat:', label: ':sweat:', image: '', unicode: '😓' },
    { value: ':tired_face:', label: ':tired_face:', image: '', unicode: '😫' },
    { value: ':worried:', label: ':worried:', image: '', unicode: '😟' },
    { value: ':frowning2:', label: ':frowning2:', image: '', unicode: '☹️' },
    { value: ':white_frowning_face:', label: ':white_frowning_face:', image: '', unicode: '☹️' },
    { value: ':slight_frown:', label: ':slight_frown:', image: '', unicode: '😦' },
    { value: ':slightly_frowning_face:', label: ':slightly_frowning_face:', image: '', unicode: '😦' },
    { value: ':hushed:', label: ':hushed:', image: '', unicode: '😯' },
    { value: ':flushed:', label: ':flushed:', image: '', unicode: '😳' },
    { value: ':fearful:', label: ':fearful:', image: '', unicode: '😨' },
    { value: ':disappointed_relieved:', label: ':disappointed_relieved:', image: '', unicode: '😥' },
    { value: ':sob:', label: ':sob:', image: '', unicode: '😭' },
    { value: ':confounded:', label: ':confounded:', image: '', unicode: '😖' },
    { value: ':disappointed:', label: ':disappointed:', image: '', unicode: '😞' },
    { value: ':weary:', label: ':weary:', image: '', unicode: '😩' },
    { value: ':yawning_face:', label: ':yawning_face:', image: '', unicode: '🥱' },
    { value: ':triumph:', label: ':triumph:', image: '', unicode: '😤' },
    { value: ':angry:', label: ':angry:', image: '', unicode: '😠' },
    { value: ':smiling_imp:', label: ':smiling_imp:', image: '', unicode: '😈' },
    { value: ':skull:', label: ':skull:', image: '', unicode: '💀' },
    { value: ':skeleton:', label: ':skeleton:', image: '', unicode: '💀' },
    { value: ':rage:', label: ':rage:', image: '', unicode: '😡' },
    { value: ':face_with_symbols_over_mouth:', label: ':face_with_symbols_over_mouth:', image: '', unicode: '🤬' },
    { value: ':imp:', label: ':imp:', image: '', unicode: '👿' },
    { value: ':skull_and_crossbones:', label: ':skull_and_crossbones:', image: '', unicode: '☠️' },
    { value: ':skull_crossbones:', label: ':skull_crossbones:', image: '', unicode: '☠️' },
    { value: ':hankey:', label: ':hankey:', image: '', unicode: '💩' },
    { value: ':poop:', label: ':poop:', image: '', unicode: '💩' },
    { value: ':shit:', label: ':shit:', image: '', unicode: '💩' },
    { value: ':poo:', label: ':poo:', image: '', unicode: '💩' },
    { value: ':japanese_ogre:', label: ':japanese_ogre:', image: '', unicode: '👹' },
    { value: ':ghost:', label: ':ghost:', image: '', unicode: '👻' },
    { value: ':space_invader:', label: ':space_invader:', image: '', unicode: '👾' },
    { value: ':clown_face:', label: ':clown_face:', image: '', unicode: '🤡' },
    { value: ':clown:', label: ':clown:', image: '', unicode: '🤡' },
    { value: ':japanese_goblin:', label: ':japanese_goblin:', image: '', unicode: '👺' },
    { value: ':alien:', label: ':alien:', image: '', unicode: '👽' },
    { value: ':robot:', label: ':robot:', image: '', unicode: '🤖' },
    { value: ':robot_face:', label: ':robot_face:', image: '', unicode: '🤖' },
    { value: ':smiley_cat:', label: ':smiley_cat:', image: '', unicode: '😺' },
    { value: ':joy_cat:', label: ':joy_cat:', image: '', unicode: '😹' },
    { value: ':smirk_cat:', label: ':smirk_cat:', image: '', unicode: '😼' },
    { value: ':scream_cat:', label: ':scream_cat:', image: '', unicode: '🙀' },
    { value: ':pouting_cat:', label: ':pouting_cat:', image: '', unicode: '😾' },
    { value: ':smile_cat:', label: ':smile_cat:', image: '', unicode: '😸' },
    { value: ':heart_eyes_cat:', label: ':heart_eyes_cat:', image: '', unicode: '😻' },
    { value: ':kissing_cat:', label: ':kissing_cat:', image: '', unicode: '😽' },
    { value: ':crying_cat_face:', label: ':crying_cat_face:', image: '', unicode: '😿' },
    { value: ':see_no_evil:', label: ':see_no_evil:', image: '', unicode: '🙈' },
    { value: ':speak_no_evil:', label: ':speak_no_evil:', image: '', unicode: '🙊' },
    { value: ':hear_no_evil:', label: ':hear_no_evil:', image: '', unicode: '🙉' },
    { value: ':love_letter:', label: ':love_letter:', image: '', unicode: '💌' },
    { value: ':gift_heart:', label: ':gift_heart:', image: '', unicode: '💝' },
    { value: ':heartpulse:', label: ':heartpulse:', image: '', unicode: '💗' },
    { value: ':revolving_hearts:', label: ':revolving_hearts:', image: '', unicode: '💞' },
    { value: ':broken_heart:', label: ':broken_heart:', image: '', unicode: '💔' },
    { value: ':mending_heart:', label: ':mending_heart:', image: '', unicode: '❤️‍🩹' },
    { value: ':orange_heart:', label: ':orange_heart:', image: '', unicode: '🧡' },
    { value: ':green_heart:', label: ':green_heart:', image: '', unicode: '💚' },
    { value: ':purple_heart:', label: ':purple_heart:', image: '', unicode: '💜' },
    { value: ':black_heart:', label: ':black_heart:', image: '', unicode: '🖤' },
    { value: ':cupid:', label: ':cupid:', image: '', unicode: '💘' },
    { value: ':sparkling_heart:', label: ':sparkling_heart:', image: '', unicode: '💖' },
    { value: ':heartbeat:', label: ':heartbeat:', image: '', unicode: '💓' },
    { value: ':two_hearts:', label: ':two_hearts:', image: '', unicode: '💕' },
    { value: ':heart_exclamation:', label: ':heart_exclamation:', image: '', unicode: '❣️' },
    { value: ':heavy_heart_exclamation_mark_ornament:', label: ':heavy_heart_exclamation_mark_ornament:', image: '', unicode: '❣️' },
    { value: ':heart_on_fire:', label: ':heart_on_fire:', image: '', unicode: '❤️‍🔥' },
    { value: ':heart:', label: ':heart:', image: '', unicode: '❤️' },
    { value: ':yellow_heart:', label: ':yellow_heart:', image: '', unicode: '💛' },
    { value: ':blue_heart:', label: ':blue_heart:', image: '', unicode: '💙' },
    { value: ':brown_heart:', label: ':brown_heart:', image: '', unicode: '🤎' },
    { value: ':white_heart:', label: ':white_heart:', image: '', unicode: '🤍' },
    { value: ':kiss:', label: ':kiss:', image: '', unicode: '💋' },
    { value: ':anger:', label: ':anger:', image: '', unicode: '💢' },
    { value: ':dizzy:', label: ':dizzy:', image: '', unicode: '💫' },
    { value: ':dash:', label: ':dash:', image: '', unicode: '💨' },
    { value: ':speech_balloon:', label: ':speech_balloon:', image: '', unicode: '💬' },
    { value: ':left_speech_bubble:', label: ':left_speech_bubble:', image: '', unicode: '🗨️' },
    { value: ':speech_left:', label: ':speech_left:', image: '', unicode: '🗨️' },
    { value: ':thought_balloon:', label: ':thought_balloon:', image: '', unicode: '💭' },
    { value: ':100:', label: ':100:', image: '', unicode: '💯' },
    { value: ':boom:', label: ':boom:', image: '', unicode: '💥' },
    { value: ':boom:', label: ':collision:', image: '', unicode: '💥' },
    { value: ':sweat_drops:', label: ':sweat_drops:', image: '', unicode: '💦' },
    { value: ':hole:', label: ':hole:', image: '', unicode: '🕳️' },
    { value: ':eye_in_speech_bubble:', label: ':eye_in_speech_bubble:', image: '', unicode: '👁️‍🗨️' },
    { value: ':anger_right:', label: ':anger_right:', image: '', unicode: '🗯️' },
    { value: ':right_anger_bubble:', label: ':right_anger_bubble:', image: '', unicode: '🗯️' },
    { value: ':zzz:', label: ':zzz:', image: '', unicode: '💤' },
    { value: ':wave:', label: ':wave:', image: '', unicode: '👋' },
    { value: ':raised_hand_with_fingers_splayed:', label: ':raised_hand_with_fingers_splayed:', image: '', unicode: '🖐️' },
    { value: ':hand_splayed:', label: ':hand_splayed:', image: '', unicode: '🖐️' },
    { value: ':vulcan:', label: ':vulcan:', image: '', unicode: '🖖' },
    { value: ':raised_hand_with_part_between_middle_and_ring_fingers:', label: ':raised_hand_with_part_between_middle_and_ring_fingers:', image: '', unicode: '🖖' },
    { value: ':raised_back_of_hand:', label: ':raised_back_of_hand:', image: '', unicode: '🤚' },
    { value: ':back_of_hand:', label: ':back_of_hand:', image: '', unicode: '🤚' },
    { value: ':raised_hand:', label: ':raised_hand:', image: '', unicode: '✋' },
    { value: ':ok_hand:', label: ':ok_hand:', image: '', unicode: '👌' },
    { value: ':pinching_hand:', label: ':pinching_hand:', image: '', unicode: '🤏' },
    { value: ':fingers_crossed:', label: ':fingers_crossed:', image: '', unicode: '🤞' },
    { value: ':hand_with_index_and_middle_finger_crossed:', label: ':hand_with_index_and_middle_finger_crossed:', image: '', unicode: '🤞' },
    { value: ':metal:', label: ':metal:', image: '', unicode: '🤘' },
    { value: ':sign_of_the_horns:', label: ':sign_of_the_horns:', image: '', unicode: '🤘' },
    { value: ':pinched_fingers:', label: ':pinched_fingers:', image: '', unicode: '🤌' },
    { value: ':v:', label: ':v:', image: '', unicode: '✌️' },
    { value: ':love_you_gesture:', label: ':love_you_gesture:', image: '', unicode: '🤟' },
    { value: ':call_me_hand:', label: ':call_me_hand:', image: '', unicode: '🤙' },
    { value: ':call_me:', label: ':call_me:', image: '', unicode: '🤙' },
    { value: ':point_left:', label: ':point_left:', image: '', unicode: '👈' },
    { value: ':point_up_2:', label: ':point_up_2:', image: '', unicode: '👆' },
    { value: ':point_down:', label: ':point_down:', image: '', unicode: '👇' },
    { value: ':point_right:', label: ':point_right:', image: '', unicode: '👉' },
    { value: ':middle_finger:', label: ':middle_finger:', image: '', unicode: '🖕' },
    { value: ':reversed_hand_with_middle_finger_extended:', label: ':reversed_hand_with_middle_finger_extended:', image: '', unicode: '🖕' },
    { value: ':point_up:', label: ':point_up:', image: '', unicode: '☝️' },
    { value: ':+1:', label: ':+1:', image: '', unicode: '👍' },
    { value: ':thumbsup:', label: ':thumbsup:', image: '', unicode: '👍' },
    { value: ':thumbup:', label: ':thumbup:', image: '', unicode: '👍' },
    { value: ':fist:', label: ':fist:', image: '', unicode: '✊' },
    { value: ':left_facing_fist:', label: ':left_facing_fist:', image: '', unicode: '🤛' },
    { value: ':left_fist:', label: ':left_fist:', image: '', unicode: '🤛' },
    { value: ':-1:', label: ':-1:', image: '', unicode: '👎' },
    { value: ':thumbsdown:', label: ':thumbsdown:', image: '', unicode: '👎' },
    { value: ':thumbdown:', label: ':thumbdown:', image: '', unicode: '👎' },
    { value: ':punch:', label: ':punch:', image: '', unicode: '👊' },
    { value: ':right_facing_fist:', label: ':right_facing_fist:', image: '', unicode: '🤜' },
    { value: ':right_fist:', label: ':right_fist:', image: '', unicode: '🤜' },
    { value: ':clap:', label: ':clap:', image: '', unicode: '👏' },
    { value: ':open_hands:', label: ':open_hands:', image: '', unicode: '👐' },
    { value: ':handshake:', label: ':handshake:', image: '', unicode: '🤝' },
    { value: ':shaking_hands:', label: ':shaking_hands:', image: '', unicode: '🤝' },
    { value: ':raised_hands:', label: ':raised_hands:', image: '', unicode: '🙌' },
    { value: ':palms_up_together:', label: ':palms_up_together:', image: '', unicode: '🤲' },
    { value: ':pray:', label: ':pray:', image: '', unicode: '🙏' },
    { value: ':writing_hand:', label: ':writing_hand:', image: '', unicode: '✍️' },
    { value: ':selfie:', label: ':selfie:', image: '', unicode: '🤳' },
    { value: ':nail_care:', label: ':nail_care:', image: '', unicode: '💅' },
    { value: ':muscle:', label: ':muscle:', image: '', unicode: '💪' },
    { value: ':mechanical_arm:', label: ':mechanical_arm:', image: '', unicode: '🦾' },
    { value: ':mechanical_leg:', label: ':mechanical_leg:', image: '', unicode: '🦿' },
    { value: ':leg:', label: ':leg:', image: '', unicode: '🦵' },
    { value: ':foot:', label: ':foot:', image: '', unicode: '🦶' },
    { value: ':ear:', label: ':ear:', image: '', unicode: '👂' },
    { value: ':ear_with_hearing_aid:', label: ':ear_with_hearing_aid:', image: '', unicode: '🦻' },
    { value: ':nose:', label: ':nose:', image: '', unicode: '👃' },
    { value: ':brain:', label: ':brain:', image: '', unicode: '🧠' },
    { value: ':anatomical_heart:', label: ':anatomical_heart:', image: '', unicode: '🫀' },
    { value: ':lungs:', label: ':lungs:', image: '', unicode: '🫁' },
    { value: ':tooth:', label: ':tooth:', image: '', unicode: '🦷' },
    { value: ':bone:', label: ':bone:', image: '', unicode: '🦴' },
    { value: ':eyes:', label: ':eyes:', image: '', unicode: '👀' },
    { value: ':eye:', label: ':eye:', image: '', unicode: '👁️' },
    { value: ':tongue:', label: ':tongue:', image: '', unicode: '👅' },
    { value: ':lips:', label: ':lips:', image: '', unicode: '👄' },
    { value: ':baby:', label: ':baby:', image: '', unicode: '👶' },
    { value: ':child:', label: ':child:', image: '', unicode: '🧒' },
    { value: ':boy:', label: ':boy:', image: '', unicode: '👦' },
    { value: ':girl:', label: ':girl:', image: '', unicode: '👧' },
    { value: ':adult:', label: ':adult:', image: '', unicode: '🧑' },
    { value: ':man:', label: ':man:', image: '', unicode: '👨' },
    { value: ':bearded_person:', label: ':bearded_person:', image: '', unicode: '🧔' },
    { value: ':man_beard:', label: ':man_beard:', image: '', unicode: '🧔‍♂️' },
    { value: ':woman_beard:', label: ':woman_beard:', image: '', unicode: '🧔‍♀️' },
    { value: ':man_red_haired:', label: ':man_red_haired:', image: '', unicode: '👨‍🦰' },
    { value: ':man_curly_haired:', label: ':man_curly_haired:', image: '', unicode: '👨‍🦱' },
    { value: ':man_white_haired:', label: ':man_white_haired:', image: '', unicode: '👨‍🦳' },
    { value: ':man_bald:', label: ':man_bald:', image: '', unicode: '👨‍🦲' },
    { value: ':woman:', label: ':woman:', image: '', unicode: '👩' },
    { value: ':woman_red_haired:', label: ':woman_red_haired:', image: '', unicode: '👩‍🦰' },
    { value: ':person_red_hair:', label: ':person_red_hair:', image: '', unicode: '🧑‍🦰' },
    { value: ':woman_curly_haired:', label: ':woman_curly_haired:', image: '', unicode: '👩‍🦱' },
    { value: ':person_curly_hair:', label: ':person_curly_hair:', image: '', unicode: '🧑‍🦱' },
    { value: ':woman_white_haired:', label: ':woman_white_haired:', image: '', unicode: '👩‍🦳' },
    { value: ':person_white_hair:', label: ':person_white_hair:', image: '', unicode: '🧑‍🦳' },
    { value: ':woman_bald:', label: ':woman_bald:', image: '', unicode: '👩‍🦲' },
    { value: ':person_bald:', label: ':person_bald:', image: '', unicode: '🧑‍🦲' },
    { value: ':blond_haired_woman:', label: ':blond_haired_woman:', image: '', unicode: '👱‍♀️' },
    { value: ':blond_haired_man:', label: ':blond_haired_man:', image: '', unicode: '👱‍♂️' },
    { value: ':older_adult:', label: ':older_adult:', image: '', unicode: '🧓' },
    { value: ':older_man:', label: ':older_man:', image: '', unicode: '👴' },
    { value: ':older_woman:', label: ':older_woman:', image: '', unicode: '👵' },
    { value: ':grandma:', label: ':grandma:', image: '', unicode: '👵' },
    { value: ':person_frowning:', label: ':person_frowning:', image: '', unicode: '🙍' },
    { value: ':man_frowning:', label: ':man_frowning:', image: '', unicode: '🙍‍♂️' },
    { value: ':woman_frowning:', label: ':woman_frowning:', image: '', unicode: '🙍‍♀️' },
    { value: ':person_with_pouting_face:', label: ':person_with_pouting_face:', image: '', unicode: '🙎' },
    { value: ':person_pouting:', label: ':person_pouting:', image: '', unicode: '🙎' },
    { value: ':man_pouting:', label: ':man_pouting:', image: '', unicode: '🙎‍♂️' },
    { value: ':woman_pouting:', label: ':woman_pouting:', image: '', unicode: '🙎‍♀️' },
    { value: ':no_good:', label: ':no_good:', image: '', unicode: '🙅' },
    { value: ':person_gesturing_no:', label: ':person_gesturing_no:', image: '', unicode: '🙅' },
    { value: ':person_gesturing_ok:', label: ':person_gesturing_ok:', image: '', unicode: '🙆' },
    { value: ':man_gesturing_ok:', label: ':man_gesturing_ok:', image: '', unicode: '🙆‍♂️' },
    { value: ':woman_gesturing_ok:', label: ':woman_gesturing_ok:', image: '', unicode: '🙆‍♀️' },
    { value: ':information_desk_person:', label: ':information_desk_person:', image: '', unicode: '💁' },
    { value: ':person_tipping_hand:', label: ':person_tipping_hand:', image: '', unicode: '💁' },
    { value: ':man_tipping_hand:', label: ':man_tipping_hand:', image: '', unicode: '💁‍♂️' },
    { value: ':woman_tipping_hand:', label: ':woman_tipping_hand:', image: '', unicode: '💁‍♀️' },
    { value: ':person_raising_hand:', label: ':person_raising_hand:', image: '', unicode: '🙋' },
    { value: ':raising_hand:', label: ':raising_hand:', image: '', unicode: '🙋' },
    { value: ':man_raising_hand:', label: ':man_raising_hand:', image: '', unicode: '🙋‍♂️' },
    { value: ':woman_raising_hand:', label: ':woman_raising_hand:', image: '', unicode: '🙋‍♀️' },
    { value: ':deaf_person:', label: ':deaf_person:', image: '', unicode: '🧏' },
    { value: ':deaf_man:', label: ':deaf_man:', image: '', unicode: '🧏‍♂️' },
    { value: ':deaf_woman:', label: ':deaf_woman:', image: '', unicode: '🧏‍♀️' },
    { value: ':bow:', label: ':bow:', image: '', unicode: '🙇' },
    { value: ':person_bowing:', label: ':person_bowing:', image: '', unicode: '🙇' },
    { value: ':man_bowing:', label: ':man_bowing:', image: '', unicode: '🙇‍♂️' },
    { value: ':woman_bowing:', label: ':woman_bowing:', image: '', unicode: '🙇‍♀️' },
    { value: ':facepalm:', label: ':facepalm:', image: '', unicode: '🤦' },
    { value: ':face_palm:', label: ':face_palm:', image: '', unicode: '🤦' },
    { value: ':person_facepalming:', label: ':person_facepalming:', image: '', unicode: '🤦' },
    { value: ':man_facepalming:', label: ':man_facepalming:', image: '', unicode: '🤦‍♂️' },
    { value: ':woman_facepalming:', label: ':woman_facepalming:', image: '', unicode: '🤦‍♀️' },
    { value: ':shrug:', label: ':shrug:', image: '', unicode: '🤷' },
    { value: ':person_shrugging:', label: ':person_shrugging:', image: '', unicode: '🤷' },
    { value: ':man_shrugging:', label: ':man_shrugging:', image: '', unicode: '🤷‍♂️' },
    { value: ':woman_shrugging:', label: ':woman_shrugging:', image: '', unicode: '🤷‍♀️' },
    { value: ':health_worker:', label: ':health_worker:', image: '', unicode: '🧑‍⚕️' },
    { value: ':man_health_worker:', label: ':man_health_worker:', image: '', unicode: '👨‍⚕️' },
    { value: ':woman_health_worker:', label: ':woman_health_worker:', image: '', unicode: '👩‍⚕️' },
    { value: ':student:', label: ':student:', image: '', unicode: '🧑‍🎓' },
    { value: ':man_student:', label: ':man_student:', image: '', unicode: '👨‍🎓' },
    { value: ':woman_student:', label: ':woman_student:', image: '', unicode: '👩‍🎓' },
    { value: ':teacher:', label: ':teacher:', image: '', unicode: '🧑‍🏫' },
    { value: ':man_teacher:', label: ':man_teacher:', image: '', unicode: '👨‍🏫' },
    { value: ':woman_teacher:', label: ':woman_teacher:', image: '', unicode: '👩‍🏫' },
    { value: ':judge:', label: ':judge:', image: '', unicode: '🧑‍⚖️' },
    { value: ':man_judge:', label: ':man_judge:', image: '', unicode: '👨‍⚖️' },
    { value: ':woman_judge:', label: ':woman_judge:', image: '', unicode: '👩‍⚖️' },
    { value: ':farmer:', label: ':farmer:', image: '', unicode: '🧑‍🌾' },
    { value: ':man_farmer:', label: ':man_farmer:', image: '', unicode: '👨‍🌾' },
    { value: ':woman_farmer:', label: ':woman_farmer:', image: '', unicode: '👩‍🌾' },
    { value: ':cook:', label: ':cook:', image: '', unicode: '🧑‍🍳' },
    { value: ':man_cook:', label: ':man_cook:', image: '', unicode: '👨‍🍳' },
    { value: ':woman_cook:', label: ':woman_cook:', image: '', unicode: '👩‍🍳' },
    { value: ':mechanic:', label: ':mechanic:', image: '', unicode: '🧑‍🔧' },
    { value: ':man_mechanic:', label: ':man_mechanic:', image: '', unicode: '👨‍🔧' },
    { value: ':woman_mechanic:', label: ':woman_mechanic:', image: '', unicode: '👩‍🔧' },
    { value: ':factory_worker:', label: ':factory_worker:', image: '', unicode: '🧑‍🏭' },
    { value: ':man_factory_worker:', label: ':man_factory_worker:', image: '', unicode: '👨‍🏭' },
    { value: ':woman_factory_worker:', label: ':woman_factory_worker:', image: '', unicode: '👩‍🏭' },
    { value: ':office_worker:', label: ':office_worker:', image: '', unicode: '🧑‍💼' },
    { value: ':man_office_worker:', label: ':man_office_worker:', image: '', unicode: '👨‍💼' },
    { value: ':woman_office_worker:', label: ':woman_office_worker:', image: '', unicode: '👩‍💼' },
    { value: ':scientist:', label: ':scientist:', image: '', unicode: '🧑‍🔬' },
    { value: ':man_scientist:', label: ':man_scientist:', image: '', unicode: '👨‍🔬' },
    { value: ':woman_scientist:', label: ':woman_scientist:', image: '', unicode: '👩‍🔬' },
    { value: ':technologist:', label: ':technologist:', image: '', unicode: '🧑‍💻' },
    { value: ':man_technologist:', label: ':man_technologist:', image: '', unicode: '👨‍💻' },
    { value: ':woman_technologist:', label: ':woman_technologist:', image: '', unicode: '👩‍💻' },
    { value: ':singer:', label: ':singer:', image: '', unicode: '🧑‍🎤' },
    { value: ':man_singer:', label: ':man_singer:', image: '', unicode: '👨‍🎤' },
    { value: ':woman_singer:', label: ':woman_singer:', image: '', unicode: '👩‍🎤' },
    { value: ':artist:', label: ':artist:', image: '', unicode: '🧑‍🎨' },
    { value: ':man_artist:', label: ':man_artist:', image: '', unicode: '👨‍🎨' },
    { value: ':woman_artist:', label: ':woman_artist:', image: '', unicode: '👩‍🎨' },
    { value: ':pilot:', label: ':pilot:', image: '', unicode: '🧑‍✈️' },
    { value: ':man_pilot:', label: ':man_pilot:', image: '', unicode: '👨‍✈️' },
    { value: ':woman_pilot:', label: ':woman_pilot:', image: '', unicode: '👩‍✈️' },
    { value: ':astronaut:', label: ':astronaut:', image: '', unicode: '🧑‍🚀' },
    { value: ':man_astronaut:', label: ':man_astronaut:', image: '', unicode: '👨‍🚀' },
    { value: ':woman_astronaut:', label: ':woman_astronaut:', image: '', unicode: '👩‍🚀' },
    { value: ':firefighter:', label: ':firefighter:', image: '', unicode: '🧑‍🚒' },
    { value: ':man_firefighter:', label: ':man_firefighter:', image: '', unicode: '👨‍🚒' },
    { value: ':woman_firefighter:', label: ':woman_firefighter:', image: '', unicode: '👩‍🚒' },
    { value: ':cop:', label: ':cop:', image: '', unicode: '👮' },
    { value: ':police_officer:', label: ':police_officer:', image: '', unicode: '👮' },
    { value: ':man_police_officer:', label: ':man_police_officer:', image: '', unicode: '👮‍♂️' },
    { value: ':woman_police_officer:', label: ':woman_police_officer:', image: '', unicode: '👮‍♀️' },
    { value: ':detective:', label: ':detective:', image: '', unicode: '🕵️' },
    { value: ':spy:', label: ':spy:', image: '', unicode: '🕵️' },
    { value: ':sleuth_or_spy:', label: ':sleuth_or_spy:', image: '', unicode: '🕵️' },
    { value: ':man_detective:', label: ':man_detective:', image: '', unicode: '🕵️‍♂️' },
    { value: ':woman_detective:', label: ':woman_detective:', image: '', unicode: '🕵️‍♀️' },
    { value: ':guard:', label: ':guard:', image: '', unicode: '💂' },
    { value: ':guardsman:', label: ':guardsman:', image: '', unicode: '💂' },
    { value: ':man_guard:', label: ':man_guard:', image: '', unicode: '💂‍♂️' },
    { value: ':woman_guard:', label: ':woman_guard:', image: '', unicode: '💂‍♀️' },
    { value: ':ninja:', label: ':ninja:', image: '', unicode: '🥷' },
    { value: ':construction_worker:', label: ':construction_worker:', image: '', unicode: '👷' },
    { value: ':man_construction_worker:', label: ':man_construction_worker:', image: '', unicode: '👷‍♂️' },
    { value: ':woman_construction_worker:', label: ':woman_construction_worker:', image: '', unicode: '👷‍♀️' },
    { value: ':prince:', label: ':prince:', image: '', unicode: '🤴' },
    { value: ':princess:', label: ':princess:', image: '', unicode: '👸' },
    { value: ':person_wearing_turban:', label: ':person_wearing_turban:', image: '', unicode: '👳' },
    { value: ':man_with_turban:', label: ':man_with_turban:', image: '', unicode: '👳' },
    { value: ':man_wearing_turban:', label: ':man_wearing_turban:', image: '', unicode: '👳‍♂️' },
    { value: ':woman_wearing_turban:', label: ':woman_wearing_turban:', image: '', unicode: '👳‍♀️' },
    { value: ':man_with_gua_pi_mao:', label: ':man_with_gua_pi_mao:', image: '', unicode: '👲' },
    { value: ':man_with_chinese_cap:', label: ':man_with_chinese_cap:', image: '', unicode: '👲' },
    { value: ':woman_with_headscarf:', label: ':woman_with_headscarf:', image: '', unicode: '🧕' },
    { value: ':person_in_tuxedo:', label: ':person_in_tuxedo:', image: '', unicode: '🤵' },
    { value: ':man_in_tuxedo:', label: ':man_in_tuxedo:', image: '', unicode: '🤵‍♂️' },
    { value: ':woman_in_tuxedo:', label: ':woman_in_tuxedo:', image: '', unicode: '🤵‍♀️' },
    { value: ':person_with_veil:', label: ':person_with_veil:', image: '', unicode: '👰' },
    { value: ':man_with_veil:', label: ':man_with_veil:', image: '', unicode: '👰‍♂️' },
    { value: ':woman_with_veil:', label: ':woman_with_veil:', image: '', unicode: '👰‍♀️' },
    { value: ':bride_with_veil:', label: ':bride_with_veil:', image: '', unicode: '👰‍♀️' },
    { value: ':pregnant_person:', label: ':pregnant_person:', image: '', unicode: '🫄' },
    { value: ':pregnant_woman:', label: ':pregnant_woman:', image: '', unicode: '🤰' },
    { value: ':expecting_woman:', label: ':expecting_woman:', image: '', unicode: '🤰' },
    { value: ':pregnant_man:', label: ':pregnant_man:', image: '', unicode: '🫃' },
    { value: ':woman_feeding_baby:', label: ':woman_feeding_baby:', image: '', unicode: '👩‍🍼' },
    { value: ':man_feeding_baby:', label: ':man_feeding_baby:', image: '', unicode: '👨‍🍼' },
    { value: ':person_feeding_baby:', label: ':person_feeding_baby:', image: '', unicode: '🧑‍🍼' },
    { value: ':angel:', label: ':angel:', image: '', unicode: '👼' },
    { value: ':santa:', label: ':santa:', image: '', unicode: '🎅' },
    { value: ':mrs_claus:', label: ':mrs_claus:', image: '', unicode: '🤶' },
    { value: ':mother_christmas:', label: ':mother_christmas:', image: '', unicode: '🤶' },
    { value: ':mx_claus:', label: ':mx_claus:', image: '', unicode: '🧑‍🎄' },
    { value: ':superhero:', label: ':superhero:', image: '', unicode: '🦸' },
    { value: ':man_superhero:', label: ':man_superhero:', image: '', unicode: '🦸‍♂️' },
    { value: ':woman_superhero:', label: ':woman_superhero:', image: '', unicode: '🦸‍♀️' },
    { value: ':supervillain:', label: ':supervillain:', image: '', unicode: '🦹' },
    { value: ':man_supervillain:', label: ':man_supervillain:', image: '', unicode: '🦹‍♂️' },
    { value: ':woman_supervillain:', label: ':woman_supervillain:', image: '', unicode: '🦹‍♀️' },
    { value: ':mage:', label: ':mage:', image: '', unicode: '🧙' },
    { value: ':man_mage:', label: ':man_mage:', image: '', unicode: '🧙‍♂️' },
    { value: ':woman_mage:', label: ':woman_mage:', image: '', unicode: '🧙‍♀️' },
    { value: ':fairy:', label: ':fairy:', image: '', unicode: '🧚' },
    { value: ':man_fairy:', label: ':man_fairy:', image: '', unicode: '🧚‍♂️' },
    { value: ':woman_fairy:', label: ':woman_fairy:', image: '', unicode: '🧚‍♀️' },
    { value: ':vampire:', label: ':vampire:', image: '', unicode: '🧛' },
    { value: ':man_vampire:', label: ':man_vampire:', image: '', unicode: '🧛‍♂️' },
    { value: ':woman_vampire:', label: ':woman_vampire:', image: '', unicode: '🧛‍♀️' },
    { value: ':merperson:', label: ':merperson:', image: '', unicode: '🧜' },
    { value: ':merman:', label: ':merman:', image: '', unicode: '🧜‍♂️' },
    { value: ':mermaid:', label: ':mermaid:', image: '', unicode: '🧜‍♀️' },
    { value: ':elf:', label: ':elf:', image: '', unicode: '🧝' },
    { value: ':man_elf:', label: ':man_elf:', image: '', unicode: '🧝‍♂️' },
    { value: ':woman_elf:', label: ':woman_elf:', image: '', unicode: '🧝‍♀️' },
    { value: ':genie:', label: ':genie:', image: '', unicode: '🧞' },
    { value: ':man_genie:', label: ':man_genie:', image: '', unicode: '🧞‍♂️' },
    { value: ':woman_genie:', label: ':woman_genie:', image: '', unicode: '🧞‍♀️' },
    { value: ':zombie:', label: ':zombie:', image: '', unicode: '🧟' },
    { value: ':man_zombie:', label: ':man_zombie:', image: '', unicode: '🧟‍♂️' },
    { value: ':woman_zombie:', label: ':woman_zombie:', image: '', unicode: '🧟‍♀️' },
    { value: ':massage:', label: ':massage:', image: '', unicode: '💆' },
    { value: ':person_getting_massage:', label: ':person_getting_massage:', image: '', unicode: '💆' },
    { value: ':man_getting_face_massage:', label: ':man_getting_face_massage:', image: '', unicode: '💆‍♂️' },
    { value: ':woman_getting_face_massage:', label: ':woman_getting_face_massage:', image: '', unicode: '💆‍♀️' },
    { value: ':haircut:', label: ':haircut:', image: '', unicode: '💇' },
    { value: ':person_getting_haircut:', label: ':person_getting_haircut:', image: '', unicode: '💇' },
    { value: ':man_getting_haircut:', label: ':man_getting_haircut:', image: '', unicode: '💇‍♂️' },
    { value: ':woman_getting_haircut:', label: ':woman_getting_haircut:', image: '', unicode: '💇‍♀️' },
    { value: ':walking:', label: ':walking:', image: '', unicode: '🚶' },
    { value: ':person_walking:', label: ':person_walking:', image: '', unicode: '🚶' },
    { value: ':man_walking:', label: ':man_walking:', image: '', unicode: '🚶‍♂️' },
    { value: ':woman_walking:', label: ':woman_walking:', image: '', unicode: '🚶‍♀️' },
    { value: ':person_standing:', label: ':person_standing:', image: '', unicode: '🧍' },
    { value: ':man_standing:', label: ':man_standing:', image: '', unicode: '🧍‍♂️' },
    { value: ':woman_standing:', label: ':woman_standing:', image: '', unicode: '🧍‍♀️' },
    { value: ':person_kneeling:', label: ':person_kneeling:', image: '', unicode: '🧎' },
    { value: ':man_kneeling:', label: ':man_kneeling:', image: '', unicode: '🧎‍♂️' },
    { value: ':woman_kneeling:', label: ':woman_kneeling:', image: '', unicode: '🧎‍♀️' },
    { value: ':person_with_probing_cane:', label: ':person_with_probing_cane:', image: '', unicode: '🧑‍🦯' },
    { value: ':man_with_probing_cane:', label: ':man_with_probing_cane:', image: '', unicode: '👨‍🦯' },
    { value: ':woman_with_probing_cane:', label: ':woman_with_probing_cane:', image: '', unicode: '👩‍🦯' },
    { value: ':person_in_motorized_wheelchair:', label: ':person_in_motorized_wheelchair:', image: '', unicode: '🧑‍🦼' },
    { value: ':man_in_motorized_wheelchair:', label: ':man_in_motorized_wheelchair:', image: '', unicode: '👨‍🦼' },
    { value: ':woman_in_motorized_wheelchair:', label: ':woman_in_motorized_wheelchair:', image: '', unicode: '👩‍🦼' },
    { value: ':person_in_manual_wheelchair:', label: ':person_in_manual_wheelchair:', image: '', unicode: '🧑‍🦽' },
    { value: ':man_in_manual_wheelchair:', label: ':man_in_manual_wheelchair:', image: '', unicode: '👨‍🦽' },
    { value: ':woman_in_manual_wheelchair:', label: ':woman_in_manual_wheelchair:', image: '', unicode: '👩‍🦽' },
    { value: ':runner:', label: ':runner:', image: '', unicode: '🏃' },
    { value: ':person_running:', label: ':person_running:', image: '', unicode: '🏃' },
    { value: ':man_running:', label: ':running_man:', image: '', unicode: '🏃‍♂️' },
    { value: ':woman_running:', label: ':running_woman:', image: '', unicode: '🏃‍♀️' },
    { value: ':dancer:', label: ':dancer:', image: '', unicode: '💃' },
    { value: ':man_dancing:', label: ':man_dancing:', image: '', unicode: '🕺' },
    { value: ':male_dancer:', label: ':male_dancer:', image: '', unicode: '🕺' },
    { value: ':business_suit_levitating:', label: ':business_suit_levitating:', image: '', unicode: '🕴️' },
    { value: ':levitate:', label: ':levitate:', image: '', unicode: '🕴️' },
    { value: ':dancers:', label: ':dancers:', image: '', unicode: '👯' },
    { value: ':people_with_bunny_ears_partying:', label: ':people_with_bunny_ears_partying:', image: '', unicode: '👯' },
    { value: ':man_with_bunny_ears_partying:', label: ':man_with_bunny_ears_partying:', image: '', unicode: '👯‍♂️' },
    { value: ':woman_with_bunny_ears_partying:', label: ':woman_with_bunny_ears_partying:', image: '', unicode: '👯‍♀️' },
    { value: ':person_in_steamy_room:', label: ':person_in_steamy_room:', image: '', unicode: '🧖' },
    { value: ':man_in_steamy_room:', label: ':man_in_steamy_room:', image: '', unicode: '🧖‍♂️' },
    { value: ':woman_in_steamy_room:', label: ':woman_in_steamy_room:', image: '', unicode: '🧖‍♀️' },
    { value: ':person_climbing:', label: ':person_climbing:', image: '', unicode: '🧗' },
    { value: ':man_climbing:', label: ':man_climbing:', image: '', unicode: '🧗‍♂️' },
    { value: ':woman_climbing:', label: ':woman_climbing:', image: '', unicode: '🧗‍♀️' },
    { value: ':person_fencing:', label: ':person_fencing:', image: '', unicode: '🤺' },
    { value: ':fencer:', label: ':fencer:', image: '', unicode: '🤺' },
    { value: ':fencing:', label: ':fencing:', image: '', unicode: '🤺' },
    { value: ':horse_racing:', label: ':horse_racing:', image: '', unicode: '🏇' },
    { value: ':skier:', label: ':skier:', image: '', unicode: '⛷️' },
    { value: ':snowboarder:', label: ':snowboarder:', image: '', unicode: '🏂' },
    { value: ':golfer:', label: ':golfer:', image: '', unicode: '🏌️' },
    { value: ':person_golfing:', label: ':person_golfing:', image: '', unicode: '🏌️' },
    { value: ':man_golfing:', label: ':man_golfing:', image: '', unicode: '🏌️‍♂️' },
    { value: ':woman_golfing:', label: ':woman_golfing:', image: '', unicode: '🏌️‍♀️' },
    { value: ':surfer:', label: ':surfer:', image: '', unicode: '🏄' },
    { value: ':person_surfing:', label: ':person_surfing:', image: '', unicode: '🏄' },
    { value: ':man_surfing_man:', label: ':man_surfing_man:', image: '', unicode: '🏄‍♂️' },
    { value: ':woman_surfing_woman:', label: ':woman_surfing_woman:', image: '', unicode: '🏄‍♀️' },
    { value: ':rowboat:', label: ':rowboat:', image: '', unicode: '🚣' },
    { value: ':person_rowboat:', label: ':person_rowboat:', image: '', unicode: '🚣' },
    { value: ':man_rowing:', label: ':man_rowing:', image: '', unicode: '🚣‍♂️' },
    { value: ':woman_rowing:', label: ':woman_rowing:', image: '', unicode: '🚣‍♀️' },
    { value: ':swimmer:', label: ':swimmer:', image: '', unicode: '🏊' },
    { value: ':person_swimmer:', label: ':person_swimmer:', image: '', unicode: '🏊' },
    { value: ':man_swimming:', label: '::man_swimming:', image: '', unicode: '🏊‍♂️' },
    { value: ':woman_swimming:', label: ':woman_swimming:', image: '', unicode: '🏊‍♀️' },
    { value: ':person_bouncing_ball:', label: ':person_bouncing_ball:', image: '', unicode: '⛹️' },
    { value: ':basket_ball_player:', label: ':basket_ball_player:', image: '', unicode: '⛹️' },
    { value: ':person_with_ball:', label: ':person_with_ball:', image: '', unicode: '⛹️' },
    { value: ':man_basketball:', label: ':man_basketball:', image: '', unicode: '⛹️‍♂️' },
    { value: ':woman_basketball:', label: ':woman_basketball:', image: '', unicode: '⛹️‍♀️' },
    { value: ':weight_lifter:', label: ':weight_lifter:', image: '', unicode: '🏋️' },
    { value: ':lifter:', label: ':lifter:', image: '', unicode: '🏋️' },
    { value: ':person_lifting_weights:', label: ':person_lifting_weights:', image: '', unicode: '🏋️' },
    { value: ':man_lifting_weights:', label: ':man_lifting_weights:', image: '', unicode: '🏋️‍♂️' },
    { value: ':woman_lifting_weights:', label: ':woman_lifting_weights:', image: '', unicode: '🏋️‍♀️' },
    { value: ':bicyclist:', label: ':bicyclist:', image: '', unicode: '🚴' },
    { value: ':person_biking:', label: ':person_biking:', image: '', unicode: '🚴' },
    { value: ':man_biking:', label: ':man_biking:', image: '', unicode: '🚴‍♂️' },
    { value: ':woman_biking:', label: ':woman_biking:', image: '', unicode: '🚴‍♀️' },
    { value: ':mountain_bicyclist:', label: ':mountain_bicyclist:', image: '', unicode: '🚵' },
    { value: ':person_mountain_biking:', label: ':person_mountain_biking:', image: '', unicode: '🚵' },
    { value: ':man_mountain_biking:', label: ':man_mountain_biking:', image: '', unicode: '🚵‍♂️' },
    { value: ':woman_mountain_biking:', label: ':woman_mountain_biking:', image: '', unicode: '🚵‍♀️' },
    { value: ':cartwheel:', label: ':cartwheel:', image: '', unicode: '🤸' },
    { value: ':person_doing_cartwheel:', label: ':person_doing_cartwheel:', image: '', unicode: '🤸' },
    { value: ':man_cartwheel:', label: ':man_cartwheel:', image: '', unicode: '🤸‍♂️' },
    { value: ':woman_cartwheel:', label: ':woman_cartwheel:', image: '', unicode: '🤸‍♀️' },
    { value: ':wrestling:', label: ':wrestling:', image: '', unicode: '🤼' },
    { value: ':wrestlers:', label: ':wrestlers:', image: '', unicode: '🤼' },
    { value: ':people_wrestling:', label: ':people_wrestling:', image: '', unicode: '🤼' },
    { value: ':men_wrestling:', label: ':men_wrestling:', image: '', unicode: '🤼‍♂️' },
    { value: ':women_wrestling:', label: ':women_wrestling:', image: '', unicode: '🤼‍♀️' },
    { value: ':water_polo:', label: ':water_polo:', image: '', unicode: '🤽' },
    { value: ':people_playing_water_polo:', label: ':people_playing_water_polo:', image: '', unicode: '🤽' },
    { value: ':man_playing_water_polo:', label: ':man_playing_water_polo:', image: '', unicode: '🤽‍♂️' },
    { value: ':woman_playing_water_polo:', label: ':woman_playing_water_polo:', image: '', unicode: '🤽‍♀️' },
    { value: ':handball:', label: ':handball:', image: '', unicode: '🤾' },
    { value: ':person_playing_handball:', label: ':person_playing_handball:', image: '', unicode: '🤾' },
    { value: ':man_playing_handball:', label: ':man_playing_handball:', image: '', unicode: '🤾‍♂️' },
    { value: ':woman_playing_handball:', label: ':woman_playing_handball:', image: '', unicode: '🤾‍♀️' },
    { value: ':juggling:', label: ':juggling:', image: '', unicode: '🤹' },
    { value: ':juggler:', label: ':juggler:', image: '', unicode: '🤹' },
    { value: ':person_juggling:', label: ':person_juggling:', image: '', unicode: '🤹' },
    { value: ':man_juggling:', label: ':man_juggling:', image: '', unicode: '🤹‍♂️' },
    { value: ':woman_juggling:', label: ':woman_juggling:', image: '', unicode: '🤹‍♀️' },
    { value: ':person_in_lotus_position:', label: ':person_in_lotus_position:', image: '', unicode: '🧘' },
    { value: ':man_in_lotus_position:', label: ':man_in_lotus_position:', image: '', unicode: '🧘‍♂️' },
    { value: ':man_in_lotus_position:', label: ':woman_in_lotus_position:', image: '', unicode: '🧘‍♀️' },
    { value: ':bath:', label: ':bath:', image: '', unicode: '🛀' },
    { value: ':sleeping_accommodation:', label: ':sleeping_accommodation:', image: '', unicode: '🛌' },
    { value: ':people_holding_hands:', label: ':people_holding_hands:', image: '', unicode: '🧑‍🤝‍🧑' },
    { value: ':two_women_holding_hands:', label: ':two_women_holding_hands:', image: '', unicode: '👭' },
    { value: ':couple:', label: ':couple:', image: '', unicode: '👫' },
    { value: ':two_men_holding_hands:', label: ':two_men_holding_hands:', image: '', unicode: '👬' },
    { value: ':couplekiss:', label: ':couplekiss:', image: '', unicode: '💏' },
    { value: ':couplekiss_mm:', label: ':couplekiss_mm:', image: '', unicode: '👨‍❤️‍💋‍👨' },
    { value: ':kiss_mm:', label: ':kiss_mm:', image: '', unicode: '👨‍❤️‍💋‍👨' },
    { value: ':couplekiss_ww:', label: ':couplekiss_ww:', image: '', unicode: '👩‍❤️‍💋‍👩' },
    { value: ':kiss_ww:', label: ':kiss_ww:', image: '', unicode: '👩‍❤️‍💋‍👩' },
    { value: ':couple_with_heart:', label: ':couple_with_heart:', image: '', unicode: '💑' },
    { value: ':couple_with_heart_woman_man:', label: ':couple_with_heart_woman_man:', image: '', unicode: '👩‍❤️‍👨' },
    { value: ':couple_with_heart_mm:', label: ':couple_with_heart_mm:', image: '', unicode: '👨‍❤️‍👨' },
    { value: ':couple_mm:', label: ':couple_mm:', image: '', unicode: '👨‍❤️‍👨' },
    { value: ':couple_with_heart_ww:', label: ':couple_ww:', image: '', unicode: '👩‍❤️‍👩' },
    { value: ':couple_ww:', label: ':couple_ww:', image: '', unicode: '👩‍❤️‍👩' },
    { value: ':family:', label: ':family:', image: '', unicode: '👪' },
    { value: ':family_man_woman_boy:', label: ':family_man_woman_boy:', image: '', unicode: '👨‍👩‍👦' },
    { value: ':family_mwg:', label: ':family_mwg:', image: '', unicode: '👨‍👩‍👧' },
    { value: ':family_mwgb:', label: ':family_mwgb:', image: '', unicode: '👨‍👩‍👧‍👦' },
    { value: ':family_mwbb:', label: ':family_mwbb:', image: '', unicode: '👨‍👩‍👦‍👦' },
    { value: ':family_mwgg:', label: ':family_mwgg:', image: '', unicode: '👨‍👩‍👧‍👧' },
    { value: ':family_mmb:', label: ':family_mmb:', image: '', unicode: '👨‍👨‍👦' },
    { value: ':family_mmg:', label: ':family_mmg:', image: '', unicode: '👨‍👨‍👧' },
    { value: ':family_mmgb:', label: ':family_mmgb:', image: '', unicode: '👨‍👨‍👧‍👦' },
    { value: ':family_mmbb:', label: ':family_mmbb:', image: '', unicode: '👨‍👨‍👦‍👦' },
    { value: ':family_mmgg:', label: ':family_mmgg:', image: '', unicode: '👨‍👨‍👧‍👧' },
    { value: ':family_wwb:', label: ':family_wwb:', image: '', unicode: '👩‍👩‍👦' },
    { value: ':family_wwg:', label: ':family_wwg:', image: '', unicode: '👩‍👩‍👧' },
    { value: ':family_wwgb:', label: ':family_wwgb:', image: '', unicode: '👩‍👩‍👧‍👦' },
    { value: ':family_wwbb:', label: ':family_wwbb:', image: '', unicode: '👩‍👩‍👦‍👦' },
    { value: ':family_wwgg:', label: ':family_wwgg:', image: '', unicode: '👩‍👩‍👧‍👧' },
    { value: ':family_man_boy:', label: ':family_man_boy:', image: '', unicode: '👨‍👦' },
    { value: ':family_man_boy_boy:', label: ':family_man_boy_boy:', image: '', unicode: '👨‍👦‍👦' },
    { value: ':family_man_girl:', label: ':family_man_girl:', image: '', unicode: '👨‍👧' },
    { value: ':family_man_girl_boy:', label: ':family_man_girl_boy:', image: '', unicode: '👨‍👧‍👦' },
    { value: ':family_man_girl_girl:', label: ':family_man_girl_girl:', image: '', unicode: '👨‍👧‍👧' },
    { value: ':family_woman_boy:', label: ':family_woman_boy:', image: '', unicode: '👩‍👦' },
    { value: ':family_woman_boy_boy:', label: ':family_woman_boy_boy:', image: '', unicode: '👩‍👦‍👦' },
    { value: ':family_woman_girl:', label: ':family_woman_girl:', image: '', unicode: '👩‍👧' },
    { value: ':family_woman_girl_boy:', label: ':family_woman_girl_boy:', image: '', unicode: '👩‍👧‍👦' },
    { value: ':family_woman_girl_girl:', label: ':family_woman_girl_girl:', image: '', unicode: '👩‍👧‍👧' },
    { value: ':speaking_head:', label: ':speaking_head:', image: '', unicode: '🗣️' },
    { value: ':speaking_head_in_silhouette:', label: ':speaking_head_in_silhouette:', image: '', unicode: '🗣️' },
    { value: ':bust_in_silhouette:', label: ':bust_in_silhouette:', image: '', unicode: '👤' },
    { value: ':busts_in_silhouette:', label: ':busts_in_silhouette:', image: '', unicode: '👥' },
    { value: ':people_hugging:', label: ':people_hugging:', image: '', unicode: '🫂' },
    { value: ':footprints:', label: ':footprints:', image: '', unicode: '👣' },
    { value: ':monkey_face:', label: ':monkey_face:', image: '', unicode: '🐵' },
    { value: ':monkey:', label: ':monkey:', image: '', unicode: '🐒' },
    { value: ':gorilla:', label: ':gorilla:', image: '', unicode: '🦍' },
    { value: ':orangutan:', label: ':orangutan:', image: '', unicode: '🦧' },
    { value: ':dog:', label: ':dog:', image: '', unicode: '🐶' },
    { value: ':dog2:', label: ':dog2:', image: '', unicode: '🐕' },
    { value: ':guide_dog:', label: ':guide_dog:', image: '', unicode: '🦮' },
    { value: ':service_dog:', label: ':service_dog:', image: '', unicode: '🐕‍🦺' },
    { value: ':poodle:', label: ':poodle:', image: '', unicode: '🐩' },
    { value: ':wolf:', label: ':wolf:', image: '', unicode: '🐺' },
    { value: ':fox_face:', label: ':fox_face:', image: '', unicode: '🦊' },
    { value: ':fox:', label: ':fox:', image: '', unicode: '🦊' },
    { value: ':raccoon:', label: ':raccoon:', image: '', unicode: '🦝' },
    { value: ':cat:', label: ':cat:', image: '', unicode: '🐱' },
    { value: ':cat2:', label: ':cat2:', image: '', unicode: '🐈' },
    { value: ':black_cat:', label: ':black_cat:', image: '', unicode: '🐈‍⬛' },
    { value: ':lion:', label: ':lion:', image: '', unicode: '🦁' },
    { value: ':lion_face:', label: ':lion_face:', image: '', unicode: '🦁' },
    { value: ':tiger:', label: ':tiger:', image: '', unicode: '🐯' },
    { value: ':tiger2:', label: ':tiger2:', image: '', unicode: '🐅' },
    { value: ':leopard:', label: ':leopard:', image: '', unicode: '🐆' },
    { value: ':horse:', label: ':horse:', image: '', unicode: '🐴' },
    { value: ':racehorse:', label: ':racehorse:', image: '', unicode: '🐎' },
    { value: ':unicorn:', label: ':unicorn:', image: '', unicode: '🦄' },
    { value: ':unicorn_face:', label: ':unicorn_face:', image: '', unicode: '🦄' },
    { value: ':zebra:', label: ':zebra:', image: '', unicode: '🦓' },
    { value: ':deer:', label: ':deer:', image: '', unicode: '🦌' },
    { value: ':bison:', label: ':bison:', image: '', unicode: '🦬' },
    { value: ':cow:', label: ':cow:', image: '', unicode: '🐮' },
    { value: ':ox:', label: ':ox:', image: '', unicode: '🐂' },
    { value: ':water_buffalo:', label: ':water_buffalo:', image: '', unicode: '🐃' },
    { value: ':cow2:', label: ':cow2:', image: '', unicode: '🐄' },
    { value: ':pig:', label: ':pig:', image: '', unicode: '🐷' },
    { value: ':pig2:', label: ':pig2:', image: '', unicode: '🐖' },
    { value: ':boar:', label: ':boar:', image: '', unicode: '🐗' },
    { value: ':pig_nose:', label: ':pig_nose:', image: '', unicode: '🐽' },
    { value: ':ram:', label: ':ram:', image: '', unicode: '🐏' },
    { value: ':sheep:', label: ':sheep:', image: '', unicode: '🐑' },
    { value: ':goat:', label: ':goat:', image: '', unicode: '🐐' },
    { value: ':dromedary_camel:', label: ':dromedary_camel:', image: '', unicode: '🐪' },
    { value: ':camel:', label: ':camel:', image: '', unicode: '🐫' },
    { value: ':llama:', label: ':llama:', image: '', unicode: '🦙' },
    { value: ':giraffe:', label: ':giraffe:', image: '', unicode: '🦒' },
    { value: ':elephant:', label: ':elephant:', image: '', unicode: '🐘' },
    { value: ':mammoth:', label: ':mammoth:', image: '', unicode: '🦣' },
    { value: ':rhinoceros:', label: ':rhinoceros:', image: '', unicode: '🦏' },
    { value: ':rhino:', label: ':rhino:', image: '', unicode: '🦏' },
    { value: ':hippopotamus:', label: ':hippopotamus:', image: '', unicode: '🦛' },
    { value: ':mouse:', label: ':mouse:', image: '', unicode: '🐭' },
    { value: ':mouse2:', label: ':mouse2:', image: '', unicode: '🐁' },
    { value: ':rat:', label: ':rat:', image: '', unicode: '🐀' },
    { value: ':hamster:', label: ':hamster:', image: '', unicode: '🐹' },
    { value: ':rabbit:', label: ':rabbit:', image: '', unicode: '🐰' },
    { value: ':rabbit2:', label: ':rabbit2:', image: '', unicode: '🐇' },
    { value: ':chipmunk:', label: ':chipmunk:', image: '', unicode: '🐿️' },
    { value: ':beaver:', label: ':beaver:', image: '', unicode: '🦫' },
    { value: ':hedgehog:', label: ':hedgehog:', image: '', unicode: '🦔' },
    { value: ':bat:', label: ':bat:', image: '', unicode: '🦇' },
    { value: ':bear:', label: ':bear:', image: '', unicode: '🐻' },
    { value: ':polar_bear:', label: ':polar_bear:', image: '', unicode: '🐻‍❄️' },
    { value: ':koala:', label: ':koala:', image: '', unicode: '🐨' },
    { value: ':panda_face:', label: ':panda_face:', image: '', unicode: '🐼' },
    { value: ':sloth:', label: ':sloth:', image: '', unicode: '🦥' },
    { value: ':otter:', label: ':otter:', image: '', unicode: '🦦' },
    { value: ':skunk:', label: ':skunk:', image: '', unicode: '🦨' },
    { value: ':kangaroo:', label: ':kangaroo:', image: '', unicode: '🦘' },
    { value: ':badger:', label: ':badger:', image: '', unicode: '🦡' },
    { value: ':feet:', label: ':feet:', image: '', unicode: '🐾' },
    { value: ':paw_prints:', label: ':paw_prints:', image: '', unicode: '🐾' },
    { value: ':turkey:', label: ':turkey:', image: '', unicode: '🦃' },
    { value: ':chicken:', label: ':chicken:', image: '', unicode: '🐔' },
    { value: ':rooster:', label: ':rooster:', image: '', unicode: '🐓' },
    { value: ':hatching_chick:', label: ':hatching_chick:', image: '', unicode: '🐣' },
    { value: ':baby_chick:', label: ':baby_chick:', image: '', unicode: '🐤' },
    { value: ':hatched_chick:', label: ':hatched_chick:', image: '', unicode: '🐥' },
    { value: ':bird:', label: ':bird:', image: '', unicode: '🐦' },
    { value: ':penguin:', label: ':penguin:', image: '', unicode: '🐧' },
    { value: ':dove:', label: ':dove:', image: '', unicode: '🕊️' },
    { value: ':dove_of_peace:', label: ':dove_of_peace:', image: '', unicode: '🕊️' },
    { value: ':eagle:', label: ':eagle:', image: '', unicode: '🦅' },
    { value: ':duck:', label: ':duck:', image: '', unicode: '🦆' },
    { value: ':swan:', label: ':swan:', image: '', unicode: '🦢' },
    { value: ':owl:', label: ':owl:', image: '', unicode: '🦉' },
    { value: ':dodo:', label: ':dodo:', image: '', unicode: '🦤' },
    { value: ':feather:', label: ':feather:', image: '', unicode: '🪶' },
    { value: ':flamingo:', label: ':flamingo:', image: '', unicode: '🦩' },
    { value: ':peacock:', label: ':peacock:', image: '', unicode: '🦚' },
    { value: ':parrot:', label: ':parrot:', image: '', unicode: '🦜' },
    { value: ':frog:', label: ':frog:', image: '', unicode: '🐸' },
    { value: ':crocodile:', label: ':crocodile:', image: '', unicode: '🐊' },
    { value: ':turtle:', label: ':turtle:', image: '', unicode: '🐢' },
    { value: ':lizard:', label: ':lizard:', image: '', unicode: '🦎' },
    { value: ':snake:', label: ':snake:', image: '', unicode: '🐍' },
    { value: ':dragon_face:', label: ':dragon_face:', image: '', unicode: '🐲' },
    { value: ':dragon:', label: ':dragon:', image: '', unicode: '🐉' },
    { value: ':sauropod:', label: ':sauropod:', image: '', unicode: '🦕' },
    { value: ':t-rex:', label: ':t-rex:', image: '', unicode: '🦖' },
    { value: ':whale:', label: ':whale:', image: '', unicode: '🐳' },
    { value: ':whale2:', label: ':whale2:', image: '', unicode: '🐋' },
    { value: ':dolphin:', label: ':dolphin:', image: '', unicode: '🐬' },
    { value: ':seal:', label: ':seal:', image: '', unicode: '🦭' },
    { value: ':fish:', label: ':fish:', image: '', unicode: '🐟' },
    { value: ':tropical_fish:', label: ':tropical_fish:', image: '', unicode: '🐠' },
    { value: ':blowfish:', label: ':blowfish:', image: '', unicode: '🐡' },
    { value: ':shark:', label: ':shark:', image: '', unicode: '🦈' },
    { value: ':octopus:', label: ':octopus:', image: '', unicode: '🐙' },
    { value: ':shell:', label: ':shell:', image: '', unicode: '🐚' },
    { value: ':snail:', label: ':snail:', image: '', unicode: '🐌' },
    { value: ':butterfly:', label: ':butterfly:', image: '', unicode: '🦋' },
    { value: ':bug:', label: ':bug:', image: '', unicode: '🐛' },
    { value: ':ant:', label: ':ant:', image: '', unicode: '🐜' },
    { value: ':bee:', label: ':bee:', image: '', unicode: '🐝' },
    { value: ':beetle:', label: ':beetle:', image: '', unicode: '🪲' },
    { value: ':lady_beetle:', label: ':lady_beetle:', image: '', unicode: '🐞' },
    { value: ':cricket:', label: ':cricket:', image: '', unicode: '🦗' },
    { value: ':cockroach:', label: ':cockroach:', image: '', unicode: '🪳' },
    { value: ':spider:', label: ':spider:', image: '', unicode: '🕷️' },
    { value: ':spider_web:', label: ':spider_web:', image: '', unicode: '🕸️' },
    { value: ':scorpion:', label: ':scorpion:', image: '', unicode: '🦂' },
    { value: ':mosquito:', label: ':mosquito:', image: '', unicode: '🦟' },
    { value: ':fly:', label: ':fly:', image: '', unicode: '🪰' },
    { value: ':worm:', label: ':worm:', image: '', unicode: '🪱' },
    { value: ':microbe:', label: ':microbe:', image: '', unicode: '🦠' },
    { value: ':bouquet:', label: ':bouquet:', image: '', unicode: '💐' },
    { value: ':cherry_blossom:', label: ':cherry_blossom:', image: '', unicode: '🌸' },
    { value: ':white_flower:', label: ':white_flower:', image: '', unicode: '💮' },
    { value: ':rosette:', label: ':rosette:', image: '', unicode: '🏵️' },
    { value: ':rose:', label: ':rose:', image: '', unicode: '🌹' },
    { value: ':wilted_flower:', label: ':wilted_flower:', image: '', unicode: '🥀' },
    { value: ':wilted_rose:', label: ':wilted_rose:', image: '', unicode: '🥀' },
    { value: ':hibiscus:', label: ':hibiscus:', image: '', unicode: '🌺' },
    { value: ':sunflower:', label: ':sunflower:', image: '', unicode: '🌻' },
    { value: ':blossom:', label: ':blossom:', image: '', unicode: '🌼' },
    { value: ':tulip:', label: ':tulip:', image: '', unicode: '🌷' },
    { value: ':seedling:', label: ':seedling:', image: '', unicode: '🌱' },
    { value: ':potted_plant:', label: ':potted_plant:', image: '', unicode: '🪴' },
    { value: ':evergreen_tree:', label: ':evergreen_tree:', image: '', unicode: '🌲' },
    { value: ':deciduous_tree:', label: ':deciduous_tree:', image: '', unicode: '🌳' },
    { value: ':palm_tree:', label: ':palm_tree:', image: '', unicode: '🌴' },
    { value: ':cactus:', label: ':cactus:', image: '', unicode: '🌵' },
    { value: ':ear_of_rice:', label: ':ear_of_rice:', image: '', unicode: '🌾' },
    { value: ':herb:', label: ':herb:', image: '', unicode: '🌿' },
    { value: ':shamrock:', label: ':shamrock:', image: '', unicode: '☘️' },
    { value: ':four_leaf_clover:', label: ':four_leaf_clover:', image: '', unicode: '🍀' },
    { value: ':maple_leaf:', label: ':maple_leaf:', image: '', unicode: '🍁' },
    { value: ':fallen_leaf:', label: ':fallen_leaf:', image: '', unicode: '🍂' },
    { value: ':leaves:', label: ':leaves:', image: '', unicode: '🍃' },
    { value: ':mushroom:', label: ':mushroom:', image: '', unicode: '🍄' },
    { value: ':grapes:', label: ':grapes:', image: '', unicode: '🍇' },
    { value: ':melon:', label: ':melon:', image: '', unicode: '🍈' },
    { value: ':watermelon:', label: ':watermelon:', image: '', unicode: '🍉' },
    { value: ':tangerine:', label: ':tangerine:', image: '', unicode: '🍊' },
    { value: ':lemon:', label: ':lemon:', image: '', unicode: '🍋' },
    { value: ':banana:', label: ':banana:', image: '', unicode: '🍌' },
    { value: ':pineapple:', label: ':pineapple:', image: '', unicode: '🍍' },
    { value: ':mango:', label: ':mango:', image: '', unicode: '🥭' },
    { value: ':apple:', label: ':apple:', image: '', unicode: '🍎' },
    { value: ':green_apple:', label: ':green_apple:', image: '', unicode: '🍏' },
    { value: ':pear:', label: ':pear:', image: '', unicode: '🍐' },
    { value: ':peach:', label: ':peach:', image: '', unicode: '🍑' },
    { value: ':cherries:', label: ':cherries:', image: '', unicode: '🍒' },
    { value: ':strawberry:', label: ':strawberry:', image: '', unicode: '🍓' },
    { value: ':blueberries:', label: ':blueberries:', image: '', unicode: '🫐' },
    { value: ':kiwifruit:', label: ':kiwifruit:', image: '', unicode: '🥝' },
    { value: ':kiwi:', label: ':kiwi:', image: '', unicode: '🥝' },
    { value: ':tomato:', label: ':tomato:', image: '', unicode: '🍅' },
    { value: ':olive:', label: ':olive:', image: '', unicode: '🫒' },
    { value: ':coconut:', label: ':coconut:', image: '', unicode: '🥥' },
    { value: ':avocado:', label: ':avocado:', image: '', unicode: '🥑' },
    { value: ':eggplant:', label: ':eggplant:', image: '', unicode: '🍆' },
    { value: ':potato:', label: ':potato:', image: '', unicode: '🥔' },
    { value: ':carrot:', label: ':carrot:', image: '', unicode: '🥕' },
    { value: ':corn:', label: ':corn:', image: '', unicode: '🌽' },
    { value: ':hot_pepper:', label: ':hot_pepper:', image: '', unicode: '🌶️' },
    { value: ':bell_pepper:', label: ':bell_pepper:', image: '', unicode: '🫑' },
    { value: ':cucumber:', label: ':cucumber:', image: '', unicode: '🥒' },
    { value: ':leafy_green:', label: ':leafy_green:', image: '', unicode: '🥬' },
    { value: ':broccoli:', label: ':broccoli:', image: '', unicode: '🥦' },
    { value: ':garlic:', label: ':garlic:', image: '', unicode: '🧄' },
    { value: ':onion:', label: ':onion:', image: '', unicode: '🧅' },
    { value: ':peanuts:', label: ':peanuts:', image: '', unicode: '🥜' },
    { value: ':shelled_peanuts:', label: ':shelled_peanuts:', image: '', unicode: '🥜' },
    { value: ':chestnut:', label: ':chestnut:', image: '', unicode: '🌰' },
    { value: ':bread:', label: ':bread:', image: '', unicode: '🍞' },
    { value: ':croissant:', label: ':croissant:', image: '', unicode: '🥐' },
    { value: ':baguette_bread:', label: ':baguette_bread:', image: '', unicode: '🥖' },
    { value: ':french_bread:', label: ':french_bread:', image: '', unicode: '🥖' },
    { value: ':flatbread:', label: ':flatbread:', image: '', unicode: '🫓' },
    { value: ':pretzel:', label: ':pretzel:', image: '', unicode: '🥨' },
    { value: ':bagel:', label: ':bagel:', image: '', unicode: '🥯' },
    { value: ':pancakes:', label: ':pancakes:', image: '', unicode: '🥞' },
    { value: ':waffle:', label: ':waffle:', image: '', unicode: '🧇' },
    { value: ':cheese:', label: ':cheese:', image: '', unicode: '🧀' },
    { value: ':cheese_wedge:', label: ':cheese_wedge:', image: '', unicode: '🧀' },
    { value: ':meat_on_bone:', label: ':meat_on_bone:', image: '', unicode: '🍖' },
    { value: ':poultry_leg:', label: ':poultry_leg:', image: '', unicode: '🍗' },
    { value: ':cut_of_meat:', label: ':cut_of_meat:', image: '', unicode: '🥩' },
    { value: ':bacon:', label: ':bacon:', image: '', unicode: '🥓' },
    { value: ':hamburger:', label: ':hamburger:', image: '', unicode: '🍔' },
    { value: ':fries:', label: ':fries:', image: '', unicode: '🍟' },
    { value: ':pizza:', label: ':pizza:', image: '', unicode: '🍕' },
    { value: ':hotdog:', label: ':hotdog:', image: '', unicode: '🌭' },
    { value: ':hot_dog:', label: ':hot_dog:', image: '', unicode: '🌭' },
    { value: ':sandwich:', label: ':sandwich:', image: '', unicode: '🥪' },
    { value: ':taco:', label: ':taco:', image: '', unicode: '🌮' },
    { value: ':burrito:', label: ':burrito:', image: '', unicode: '🌯' },
    { value: ':tamale:', label: ':tamale:', image: '', unicode: '🫔' },
    { value: ':stuffed_flatbread:', label: ':stuffed_flatbread:', image: '', unicode: '🥙' },
    { value: ':stuffed_pita:', label: ':stuffed_pita:', image: '', unicode: '🥙' },
    { value: ':falafel:', label: ':falafel:', image: '', unicode: '🧆' },
    { value: ':egg:', label: ':egg:', image: '', unicode: '🥚' },
    { value: ':cooking:', label: ':cooking:', image: '', unicode: '🍳' },
    { value: ':shallow_pan_of_food:', label: ':shallow_pan_of_food:', image: '', unicode: '🥘' },
    { value: ':paella:', label: ':paella:', image: '', unicode: '🥘' },
    { value: ':stew:', label: ':stew:', image: '', unicode: '🍲' },
    { value: ':fondue:', label: ':fondue:', image: '', unicode: '🫕' },
    { value: ':bowl_with_spoon:', label: ':bowl_with_spoon:', image: '', unicode: '🥣' },
    { value: ':green_salad:', label: ':green_salad:', image: '', unicode: '🥗' },
    { value: ':salad:', label: ':salad:', image: '', unicode: '🥗' },
    { value: ':popcorn:', label: ':popcorn:', image: '', unicode: '🍿' },
    { value: ':butter:', label: ':butter:', image: '', unicode: '🧈' },
    { value: ':salt:', label: ':salt:', image: '', unicode: '🧂' },
    { value: ':canned_food:', label: ':canned_food:', image: '', unicode: '🥫' },
    { value: ':bento:', label: ':bento:', image: '', unicode: '🍱' },
    { value: ':rice_cracker:', label: ':rice_cracker:', image: '', unicode: '🍘' },
    { value: ':rice_ball:', label: ':rice_ball:', image: '', unicode: '🍙' },
    { value: ':rice:', label: ':rice:', image: '', unicode: '🍚' },
    { value: ':curry:', label: ':curry:', image: '', unicode: '🍛' },
    { value: ':ramen:', label: ':ramen:', image: '', unicode: '🍜' },
    { value: ':spaghetti:', label: ':spaghetti:', image: '', unicode: '🍝' },
    { value: ':sweet_potato:', label: ':sweet_potato:', image: '', unicode: '🍠' },
    { value: ':oden:', label: ':oden:', image: '', unicode: '🍢' },
    { value: ':sushi:', label: ':sushi:', image: '', unicode: '🍣' },
    { value: ':fried_shrimp:', label: ':fried_shrimp:', image: '', unicode: '🍤' },
    { value: ':fish_cake:', label: ':fish_cake:', image: '', unicode: '🍥' },
    { value: ':moon_cake:', label: ':moon_cake:', image: '', unicode: '🥮' },
    { value: ':dango:', label: ':dango:', image: '', unicode: '🍡' },
    { value: ':dumpling:', label: ':dumpling:', image: '', unicode: '🥟' },
    { value: ':fortune_cookie:', label: ':fortune_cookie:', image: '', unicode: '🥠' },
    { value: ':takeout_box:', label: ':takeout_box:', image: '', unicode: '🥡' },
    { value: ':crab:', label: ':crab:', image: '', unicode: '🦀' },
    { value: ':lobster:', label: ':lobster:', image: '', unicode: '🦞' },
    { value: ':shrimp:', label: ':shrimp:', image: '', unicode: '🦐' },
    { value: ':squid:', label: ':squid:', image: '', unicode: '🦑' },
    { value: ':oyster:', label: ':oyster:', image: '', unicode: '🦪' },
    { value: ':icecream:', label: ':icecream:', image: '', unicode: '🍦' },
    { value: ':shaved_ice:', label: ':shaved_ice:', image: '', unicode: '🍧' },
    { value: ':ice_cream:', label: ':ice_cream:', image: '', unicode: '🍨' },
    { value: ':doughnut:', label: ':doughnut:', image: '', unicode: '🍩' },
    { value: ':cookie:', label: ':cookie:', image: '', unicode: '🍪' },
    { value: ':birthday:', label: ':birthday:', image: '', unicode: '🎂' },
    { value: ':cake:', label: ':cake:', image: '', unicode: '🍰' },
    { value: ':cupcake:', label: ':cupcake:', image: '', unicode: '🧁' },
    { value: ':pie:', label: ':pie:', image: '', unicode: '🥧' },
    { value: ':chocolate_bar:', label: ':chocolate_bar:', image: '', unicode: '🍫' },
    { value: ':candy:', label: ':candy:', image: '', unicode: '🍬' },
    { value: ':lollipop:', label: ':lollipop:', image: '', unicode: '🍭' },
    { value: ':custard:', label: ':custard:', image: '', unicode: '🍮' },
    { value: ':pudding:', label: ':pudding:', image: '', unicode: '🍮' },
    { value: ':flan:', label: ':flan:', image: '', unicode: '🍮' },
    { value: ':honey_pot:', label: ':honey_pot:', image: '', unicode: '🍯' },
    { value: ':baby_bottle:', label: ':baby_bottle:', image: '', unicode: '🍼' },
    { value: ':milk:', label: ':milk:', image: '', unicode: '🥛' },
    { value: ':glass_of_milk:', label: ':glass_of_milk:', image: '', unicode: '🥛' },
    { value: ':coffee:', label: ':coffee:', image: '', unicode: '☕' },
    { value: ':teapot:', label: ':teapot:', image: '', unicode: '🫖' },
    { value: ':tea:', label: ':tea:', image: '', unicode: '🍵' },
    { value: ':sake:', label: ':sake:', image: '', unicode: '🍶' },
    { value: ':champagne:', label: ':champagne:', image: '', unicode: '🍾' },
    { value: ':bottle_with_popping_cork:', label: ':bottle_with_popping_cork:', image: '', unicode: '🍾' },
    { value: ':wine_glass:', label: ':wine_glass:', image: '', unicode: '🍷' },
    { value: ':cocktail:', label: ':cocktail:', image: '', unicode: '🍸' },
    { value: ':tropical_drink:', label: ':tropical_drink:', image: '', unicode: '🍹' },
    { value: ':beer:', label: ':beer:', image: '', unicode: '🍺' },
    { value: ':beers:', label: ':beers:', image: '', unicode: '🍻' },
    { value: ':clinking_glass:', label: ':clinking_glass:', image: '', unicode: '🥂' },
    { value: ':champagne_glass:', label: ':champagne_glass:', image: '', unicode: '🥂' },
    { value: ':tumbler_glass:', label: ':tumbler_glass:', image: '', unicode: '🥃' },
    { value: ':whisky:', label: ':whisky:', image: '', unicode: '🥃' },
    { value: ':cup_with_straw:', label: ':cup_with_straw:', image: '', unicode: '🥤' },
    { value: ':bubble_tea:', label: ':bubble_tea:', image: '', unicode: '🧋' },
    { value: ':beverage_box:', label: ':beverage_box:', image: '', unicode: '🧃' },
    { value: ':mate:', label: ':mate:', image: '', unicode: '🧉' },
    { value: ':ice_cube:', label: ':ice_cube:', image: '', unicode: '🧊' },
    { value: ':chopsticks:', label: ':chopsticks:', image: '', unicode: '🥢' },
    { value: ':fork_knife_plate:', label: ':fork_knife_plate:', image: '', unicode: '🍽️' },
    { value: ':fork_and_knife_with_plate:', label: ':fork_and_knife_with_plate:', image: '', unicode: '🍽️' },
    { value: ':fork_and_knife:', label: ':fork_and_knife:', image: '', unicode: '🍴' },
    { value: ':spoon:', label: ':spoon:', image: '', unicode: '🥄' },
    { value: ':knife:', label: ':knife:', image: '', unicode: '🔪' },
    { value: ':amphora:', label: ':amphora:', image: '', unicode: '🏺' },
    { value: ':earth_africa:', label: ':earth_africa:', image: '', unicode: '🌍' },
    { value: ':earth_americas:', label: ':earth_americas:', image: '', unicode: '🌎' },
    { value: ':earth_asia:', label: ':earth_asia:', image: '', unicode: '🌏' },
    { value: ':globe_with_meridians:', label: ':globe_with_meridians:', image: '', unicode: '🌐' },
    { value: ':world_map:', label: ':world_map:', image: '', unicode: '🗺️' },
    { value: ':map:', label: ':map:', image: '', unicode: '🗺️' },
    { value: ':japan:', label: ':japan:', image: '', unicode: '🗾' },
    { value: ':compass:', label: ':compass:', image: '', unicode: '🧭' },
    { value: ':mountain_snow:', label: ':mountain_snow:', image: '', unicode: '🏔️' },
    { value: ':snow_capped_mountain:', label: ':snow_capped_mountain:', image: '', unicode: '🏔️' },
    { value: ':mountain:', label: ':mountain:', image: '', unicode: '⛰️' },
    { value: ':volcano:', label: ':volcano:', image: '', unicode: '🌋' },
    { value: ':mount_fuji:', label: ':mount_fuji:', image: '', unicode: '🗻' },
    { value: ':camping:', label: ':camping:', image: '', unicode: '🏕️' },
    { value: ':beach_umbrella:', label: ':beach_umbrella:', image: '', unicode: '🏖️' },
    { value: ':umbrella_on_ground:', label: ':umbrella_on_ground:', image: '', unicode: '🏖️' },
    { value: ':desert:', label: ':desert:', image: '', unicode: '🏜️' },
    { value: ':desert_island:', label: ':desert_island:', image: '', unicode: '🏝️' },
    { value: ':island:', label: ':island:', image: '', unicode: '🏝️' },
    { value: ':national_park:', label: ':national_park:', image: '', unicode: '🏞️' },
    { value: ':park:', label: ':park:', image: '', unicode: '🏞️' },
    { value: ':stadium:', label: ':stadium:', image: '', unicode: '🏟️' },
    { value: ':classical_building:', label: ':classical_building:', image: '', unicode: '🏛️' },
    { value: ':building_construction:', label: ':building_construction:', image: '', unicode: '🏗️' },
    { value: ':construction_site:', label: ':construction_site:', image: '', unicode: '🏗️' },
    { value: ':bricks:', label: ':bricks:', image: '', unicode: '🧱' },
    { value: ':rock:', label: ':rock:', image: '', unicode: '🪨' },
    { value: ':wood:', label: ':wood:', image: '', unicode: '🪵' },
    { value: ':hut:', label: ':hut:', image: '', unicode: '🛖' },
    { value: ':homes:', label: ':homes:', image: '', unicode: '🏘️' },
    { value: ':house_buildings:', label: ':house_buildings:', image: '', unicode: '🏘️' },
    { value: ':derelict_house_building:', label: ':derelict_house_building:', image: '', unicode: '🏚️' },
    { value: ':house_abandoned:', label: ':house_abandoned:', image: '', unicode: '🏚️' },
    { value: ':house:', label: ':house:', image: '', unicode: '🏠' },
    { value: ':house_with_garden:', label: ':house_with_garden:', image: '', unicode: '🏡' },
    { value: ':office:', label: ':office:', image: '', unicode: '🏢' },
    { value: ':post_office:', label: ':post_office:', image: '', unicode: '🏣' },
    { value: ':european_post_office:', label: ':european_post_office:', image: '', unicode: '🏤' },
    { value: ':hospital:', label: ':hospital:', image: '', unicode: '🏥' },
    { value: ':bank:', label: ':bank:', image: '', unicode: '🏦' },
    { value: ':hotel:', label: ':hotel:', image: '', unicode: '🏨' },
    { value: ':love_hotel:', label: ':love_hotel:', image: '', unicode: '🏩' },
    { value: ':convenience_store:', label: ':convenience_store:', image: '', unicode: '🏪' },
    { value: ':school:', label: ':school:', image: '', unicode: '🏫' },
    { value: ':department_store:', label: ':department_store:', image: '', unicode: '🏬' },
    { value: ':factory:', label: ':factory:', image: '', unicode: '🏭' },
    { value: ':japanese_castle:', label: ':japanese_castle:', image: '', unicode: '🏯' },
    { value: ':european_castle:', label: ':european_castle:', image: '', unicode: '🏰' },
    { value: ':wedding:', label: ':wedding:', image: '', unicode: '💒' },
    { value: ':tokyo_tower:', label: ':tokyo_tower:', image: '', unicode: '🗼' },
    { value: ':statue_of_liberty:', label: ':statue_of_liberty:', image: '', unicode: '🗽' },
    { value: ':church:', label: ':church:', image: '', unicode: '⛪' },
    { value: ':mosque:', label: ':mosque:', image: '', unicode: '🕌' },
    { value: ':hindu_temple:', label: ':hindu_temple:', image: '', unicode: '🛕' },
    { value: ':synagogue:', label: ':synagogue:', image: '', unicode: '🕍' },
    { value: ':shinto_shrine:', label: ':shinto_shrine:', image: '', unicode: '⛩️' },
    { value: ':kaaba:', label: ':kaaba:', image: '', unicode: '🕋' },
    { value: ':fountain:', label: ':fountain:', image: '', unicode: '⛲' },
    { value: ':tent:', label: ':tent:', image: '', unicode: '⛺' },
    { value: ':foggy:', label: ':foggy:', image: '', unicode: '🌁' },
    { value: ':night_with_stars:', label: ':night_with_stars:', image: '', unicode: '🌃' },
    { value: ':cityscape:', label: ':cityscape:', image: '', unicode: '🏙️' },
    { value: ':sunrise_over_mountains:', label: ':sunrise_over_mountains:', image: '', unicode: '🌄' },
    { value: ':sunrise:', label: ':sunrise:', image: '', unicode: '🌅' },
    { value: ':city_sunset:', label: ':city_sunset:', image: '', unicode: '🌆' },
    { value: ':city_sunrise:', label: ':city_sunrise:', image: '', unicode: '🌇' },
    { value: ':bridge_at_night:', label: ':bridge_at_night:', image: '', unicode: '🌉' },
    { value: ':hotsprings:', label: ':hotsprings:', image: '', unicode: '♨️' },
    { value: ':carousel_horse:', label: ':carousel_horse:', image: '', unicode: '🎠' },
    { value: ':ferris_wheel:', label: ':ferris_wheel:', image: '', unicode: '🎡' },
    { value: ':roller_coaster:', label: ':roller_coaster:', image: '', unicode: '🎢' },
    { value: ':barber:', label: ':barber:', image: '', unicode: '💈' },
    { value: ':circus_tent:', label: ':circus_tent:', image: '', unicode: '🎪' },
    { value: ':steam_locomotive:', label: ':steam_locomotive:', image: '', unicode: '🚂' },
    { value: ':railway_car:', label: ':railway_car:', image: '', unicode: '🚃' },
    { value: ':bullettrain_side:', label: ':bullettrain_side:', image: '', unicode: '🚄' },
    { value: ':bullettrain_front:', label: ':bullettrain_front:', image: '', unicode: '🚅' },
    { value: ':train2:', label: ':train2:', image: '', unicode: '🚆' },
    { value: ':metro:', label: ':metro:', image: '', unicode: '🚇' },
    { value: ':light_rail:', label: ':light_rail:', image: '', unicode: '🚈' },
    { value: ':station:', label: ':station:', image: '', unicode: '🚉' },
    { value: ':tram:', label: ':tram:', image: '', unicode: '🚊' },
    { value: ':monorail:', label: ':monorail:', image: '', unicode: '🚝' },
    { value: ':mountain_railway:', label: ':mountain_railway:', image: '', unicode: '🚞' },
    { value: ':train:', label: ':train:', image: '', unicode: '🚋' },
    { value: ':bus:', label: ':bus:', image: '', unicode: '🚌' },
    { value: ':oncoming_bus:', label: ':oncoming_bus:', image: '', unicode: '🚍' },
    { value: ':trolleybus:', label: ':trolleybus:', image: '', unicode: '🚎' },
    { value: ':minibus:', label: ':minibus:', image: '', unicode: '🚐' },
    { value: ':ambulance:', label: ':ambulance:', image: '', unicode: '🚑' },
    { value: ':fire_engine:', label: ':fire_engine:', image: '', unicode: '🚒' },
    { value: ':police_car:', label: ':police_car:', image: '', unicode: '🚓' },
    { value: ':oncoming_police_car:', label: ':oncoming_police_car:', image: '', unicode: '🚔' },
    { value: ':taxi:', label: ':taxi:', image: '', unicode: '🚕' },
    { value: ':oncoming_taxi:', label: ':oncoming_taxi:', image: '', unicode: '🚖' },
    { value: ':red_car:', label: ':red_car:', image: '', unicode: '🚘' },
    { value: ':oncoming_automobile:', label: ':oncoming_automobile:', image: '', unicode: '🚘' },
    { value: ':blue_car:', label: ':blue_car:', image: '', unicode: '🚙' },
    { value: ':pickup_truck:', label: ':pickup_truck:', image: '', unicode: '🛻' },
    { value: ':truck:', label: ':truck:', image: '', unicode: '🚚' },
    { value: ':articulated_lorry:', label: ':articulated_lorry:', image: '', unicode: '🚛' },
    { value: ':tractor:', label: ':tractor:', image: '', unicode: '🚜' },
    { value: ':racing_car:', label: ':racing_car:', image: '', unicode: '🏎️' },
    { value: ':race_car:', label: ':race_car:', image: '', unicode: '🏎️' },
    { value: ':motorcycle:', label: ':motorcycle:', image: '', unicode: '🏍️' },
    { value: ':racing_motorcycle:', label: ':racing_motorcycle:', image: '', unicode: '🏍️' },
    { value: ':motor_scooter:', label: ':motor_scooter:', image: '', unicode: '🛵' },
    { value: ':motorbike:', label: ':motorbike:', image: '', unicode: '🛵' },
    { value: ':manual_wheelchair:', label: ':manual_wheelchair:', image: '', unicode: '🦽' },
    { value: ':motorized_wheelchair:', label: ':motorized_wheelchair:', image: '', unicode: '🦼' },
    { value: ':auto_rickshaw:', label: ':auto_rickshaw:', image: '', unicode: '🛺' },
    { value: ':bike:', label: ':bike:', image: '', unicode: '🚲' },
    { value: ':scooter:', label: ':scooter:', image: '', unicode: '🛴' },
    { value: ':skateboard:', label: ':skateboard:', image: '', unicode: '🛹' },
    { value: ':roller_skate:', label: ':roller_skate:', image: '', unicode: '🛼' },
    { value: ':busstop:', label: ':busstop:', image: '', unicode: '🚏' },
    { value: ':motorway:', label: ':motorway:', image: '', unicode: '🛣️' },
    { value: ':railway_track:', label: ':railway_track:', image: '', unicode: '🛤️' },
    { value: ':railroad_track:', label: ':railroad_track:', image: '', unicode: '🛤️' },
    { value: ':oil_drum:', label: ':oil_drum:', image: '', unicode: '🛢️' },
    { value: ':oil:', label: ':oil:', image: '', unicode: '🛢️' },
    { value: ':fuelpump:', label: ':fuelpump:', image: '', unicode: '⛽' },
    { value: ':rotating_light:', label: ':rotating_light:', image: '', unicode: '🚨' },
    { value: ':traffic_light:', label: ':traffic_light:', image: '', unicode: '🚥' },
    { value: ':vertical_traffic_light:', label: ':vertical_traffic_light:', image: '', unicode: '🚦' },
    { value: ':stop_sign:', label: ':stop_sign:', image: '', unicode: '🛑' },
    { value: ':octagonal_sign:', label: ':octagonal_sign:', image: '', unicode: '🛑' },
    { value: ':construction:', label: ':construction:', image: '', unicode: '🚧' },
    { value: ':anchor:', label: ':anchor:', image: '', unicode: '⚓' },
    { value: ':sailboat:', label: ':sailboat:', image: '', unicode: '⛵' },
    { value: ':canoe:', label: ':canoe:', image: '', unicode: '🛶' },
    { value: ':kayak:', label: ':kayak:', image: '', unicode: '🛶' },
    { value: ':speedboat:', label: ':speedboat:', image: '', unicode: '🚤' },
    { value: ':passenger_ship:', label: ':passenger_ship:', image: '', unicode: '🛳️' },
    { value: ':cruise_ship:', label: ':cruise_ship:', image: '', unicode: '🛳️' },
    { value: ':ferry:', label: ':ferry:', image: '', unicode: '⛴️' },
    { value: ':motorboat:', label: ':motorboat:', image: '', unicode: '🛥️' },
    { value: ':ship:', label: ':ship:', image: '', unicode: '🚢' },
    { value: ':airplane:', label: ':airplane:', image: '', unicode: '✈️' },
    { value: ':small_airplane:', label: ':small_airplane:', image: '', unicode: '🛩️' },
    { value: ':airplane_small:', label: ':airplane_small:', image: '', unicode: '🛩️' },
    { value: ':airplane_departure:', label: ':airplane_departure:', image: '', unicode: '🛫' },
    { value: ':airplane_arriving:', label: ':airplane_arriving:', image: '', unicode: '🛬' },
    { value: ':parachute:', label: ':parachute:', image: '', unicode: '🪂' },
    { value: ':seat:', label: ':seat:', image: '', unicode: '💺' },
    { value: ':helicopter:', label: ':helicopter:', image: '', unicode: '🚁' },
    { value: ':suspension_railway:', label: ':suspension_railway:', image: '', unicode: '🚟' },
    { value: ':mountain_cableway:', label: ':mountain_cableway:', image: '', unicode: '🚠' },
    { value: ':aerial_tramway:', label: ':aerial_tramway:', image: '', unicode: '🚡' },
    { value: ':satellite_orbital:', label: ':satellite_orbital:', image: '', unicode: '🛰️' },
    { value: ':rocket:', label: ':rocket:', image: '', unicode: '🚀' },
    { value: ':flying_saucer:', label: ':flying_saucer:', image: '', unicode: '🛸' },
    { value: ':bellhop_bell:', label: ':bellhop_bell:', image: '', unicode: '🛎️' },
    { value: ':bellhop:', label: ':bellhop:', image: '', unicode: '🛎️' },
    { value: ':luggage:', label: ':luggage:', image: '', unicode: '🧳' },
    { value: ':hourglass:', label: ':hourglass:', image: '', unicode: '⌛' },
    { value: ':hourglass_flowing_sand:', label: ':hourglass_flowing_sand:', image: '', unicode: '⏳' },
    { value: ':watch:', label: ':watch:', image: '', unicode: '⌚' },
    { value: ':alarm_clock:', label: ':alarm_clock:', image: '', unicode: '⏰' },
    { value: ':stopwatch:', label: ':stopwatch:', image: '', unicode: '⏱️' },
    { value: ':timer_clock:', label: ':timer_clock:', image: '', unicode: '⏲️' },
    { value: ':timer:', label: ':timer:', image: '', unicode: '⏲️' },
    { value: ':clock:', label: ':clock:', image: '', unicode: '🕰️' },
    { value: ':mantlepiece_clock:', label: ':mantlepiece_clock:', image: '', unicode: '🕰️' },
    { value: ':clock12:', label: ':clock12:', image: '', unicode: '🕛' },
    { value: ':clock1230:', label: ':clock1230:', image: '', unicode: '🕧' },
    { value: ':clock1:', label: ':clock1:', image: '', unicode: '🕐' },
    { value: ':clock130:', label: ':clock130:', image: '', unicode: '🕜' },
    { value: ':clock2:', label: ':clock2:', image: '', unicode: '🕑' },
    { value: ':clock230:', label: ':clock230:', image: '', unicode: '🕝' },
    { value: ':clock3:', label: ':clock3:', image: '', unicode: '🕒' },
    { value: ':clock330:', label: ':clock330:', image: '', unicode: '🕞' },
    { value: ':clock4:', label: ':clock4:', image: '', unicode: '🕓' },
    { value: ':clock430:', label: ':clock430:', image: '', unicode: '🕟' },
    { value: ':clock5:', label: ':clock5:', image: '', unicode: '🕔' },
    { value: ':clock530:', label: ':clock530:', image: '', unicode: '🕠' },
    { value: ':clock6:', label: ':clock6:', image: '', unicode: '🕕' },
    { value: ':clock630:', label: ':clock630:', image: '', unicode: '🕡' },
    { value: ':clock7:', label: ':clock7:', image: '', unicode: '🕖' },
    { value: ':clock730:', label: ':clock730:', image: '', unicode: '🕢' },
    { value: ':clock8:', label: ':clock8:', image: '', unicode: '🕗' },
    { value: ':clock830:', label: ':clock830:', image: '', unicode: '🕣' },
    { value: ':clock9:', label: ':clock9:', image: '', unicode: '🕤' },
    { value: ':clock930:', label: ':clock930:', image: '', unicode: '🕥' },
    { value: ':clock10:', label: ':clock10:', image: '', unicode: '🕚' },
    { value: ':clock1030:', label: ':clock1030:', image: '', unicode: '🕦' },
    { value: ':clock11:', label: ':clock11:', image: '', unicode: '🕚' },
    { value: ':clock1130:', label: ':clock1130:', image: '', unicode: '🕦' },
    { value: ':new_moon:', label: ':new_moon:', image: '', unicode: '🌑' },
    { value: ':waxing_crescent_moon:', label: ':waxing_crescent_moon:', image: '', unicode: '🌒' },
    { value: ':first_quarter_moon:', label: ':first_quarter_moon:', image: '', unicode: '🌓' },
    { value: ':waxing_gibbous_moon:', label: ':waxing_gibbous_moon:', image: '', unicode: '🌔' },
    { value: ':full_moon:', label: ':full_moon:', image: '', unicode: '🌕' },
    { value: ':waning_gibbous_moon:', label: ':waning_gibbous_moon:', image: '', unicode: '🌖' },
    { value: ':last_quarter_moon:', label: ':last_quarter_moon:', image: '', unicode: '🌗' },
    { value: ':waning_crescent_moon:', label: ':waning_crescent_moon:', image: '', unicode: '🌘' },
    { value: ':crescent_moon:', label: ':crescent_moon:', image: '', unicode: '🌙' },
    { value: ':new_moon_with_face:', label: ':new_moon_with_face:', image: '', unicode: '🌚' },
    { value: ':first_quarter_moon_with_face:', label: ':first_quarter_moon_with_face:', image: '', unicode: '🌛' },
    { value: ':last_quarter_moon_with_face:', label: ':last_quarter_moon_with_face:', image: '', unicode: '🌜' },
    { value: ':thermometer:', label: ':thermometer:', image: '', unicode: '🌡️' },
    { value: ':sunny:', label: ':sunny:', image: '', unicode: '☀️' },
    { value: ':full_moon_with_face:', label: ':full_moon_with_face:', image: '', unicode: '🌝' },
    { value: ':sun_with_face:', label: ':sun_with_face:', image: '', unicode: '🌞' },
    { value: ':ringed_planet:', label: ':ringed_planet:', image: '', unicode: '🪐' },
    { value: ':star:', label: ':star:', image: '', unicode: '⭐' },
    { value: ':star2:', label: ':star2:', image: '', unicode: '🌟' },
    { value: ':stars:', label: ':stars:', image: '', unicode: '🌠' },
    { value: ':milky_way:', label: ':milky_way:', image: '', unicode: '🌌' },
    { value: ':cloud:', label: ':cloud:', image: '', unicode: '☁️' },
    { value: ':partly_sunny:', label: ':partly_sunny:', image: '', unicode: '⛅' },
    { value: ':thunder_cloud_rain:', label: ':thunder_cloud_rain:', image: '', unicode: '⛈️' },
    { value: ':thunder_cloud_and_rain:', label: ':thunder_cloud_and_rain:', image: '', unicode: '⛈️' },
    { value: ':white_sun_small_cloud:', label: ':white_sun_small_cloud:', image: '', unicode: '🌤️' },
    { value: ':white_sun_with_small_cloud:', label: ':white_sun_with_small_cloud:', image: '', unicode: '🌤️' },
    { value: ':White_sun_cloud:', label: ':White_sun_cloud:', image: '', unicode: '🌥️' },
    { value: ':white_sun_behind_cloud:', label: ':white_sun_behind_cloud:', image: '', unicode: '🌥️' },
    { value: ':white_sun_rain_cloud:', label: ':white_sun_rain_cloud:', image: '', unicode: '🌦️' },
    { value: ':white_sun_behind_cloud_with_rain:', label: ':white_sun_behind_cloud_with_rain:', image: '', unicode: '🌦️' },
    { value: ':cloud_with_rain:', label: ':cloud_with_rain:', image: '', unicode: '🌧️' },
    { value: ':cloud_rain:', label: ':cloud_rain:', image: '', unicode: '🌧️' },
    { value: ':cloud_with_snow:', label: ':cloud_with_snow:', image: '', unicode: '🌨️' },
    { value: ':cloud_snow:', label: ':cloud_snow:', image: '', unicode: '🌨️' },
    { value: ':cloud_with_lightning:', label: ':cloud_with_lightning:', image: '', unicode: '🌩️' },
    { value: ':cloud_lightning:', label: ':cloud_lightning:', image: '', unicode: '🌩️' },
    { value: ':cloud_tornado:', label: ':cloud_tornado:', image: '', unicode: '🌪️' },
    { value: ':cloud_with_tornado:', label: ':cloud_with_tornado:', image: '', unicode: '🌪️' },
    { value: ':fog:', label: ':fog:', image: '', unicode: '🌫️' },
    { value: ':wind_blowing_face:', label: ':wind_blowing_face:', image: '', unicode: '🌬️' },
    { value: ':cyclone:', label: ':cyclone:', image: '', unicode: '🌀' },
    { value: ':rainbow:', label: ':rainbow:', image: '', unicode: '🌈' },
    { value: ':closed_umbrella:', label: ':closed_umbrella:', image: '', unicode: '🌂' },
    { value: ':umbrella2:', label: ':umbrella2:', image: '', unicode: '☂️' },
    { value: ':umbrella:', label: ':umbrella:', image: '', unicode: '☔' },
    { value: ':zap:', label: ':zap:', image: '', unicode: '⚡' },
    { value: ':snowflake:', label: ':snowflake:', image: '', unicode: '❄️' },
    { value: ':snowman2:', label: ':snowman2:', image: '', unicode: '☃️' },
    { value: ':snowman:', label: ':snowman:', image: '', unicode: '⛄' },
    { value: ':comet:', label: ':comet:', image: '', unicode: '☄️' },
    { value: ':fire:', label: ':fire:', image: '', unicode: '🔥' },
    { value: ':flame:', label: ':flame:', image: '', unicode: '🔥' },
    { value: ':droplet:', label: ':droplet:', image: '', unicode: '💧' },
    { value: ':ocean:', label: ':ocean:', image: '', unicode: '🌊' },
    { value: ':jack_o_lantern:', label: ':jack_o_lantern:', image: '', unicode: '🎃' },
    { value: ':christmas_tree:', label: ':christmas_tree:', image: '', unicode: '🎄' },
    { value: ':fireworks:', label: ':fireworks:', image: '', unicode: '🎆' },
    { value: ':sparkler:', label: ':sparkler:', image: '', unicode: '🎇' },
    { value: ':firecracker:', label: ':firecracker:', image: '', unicode: '🧨' },
    { value: ':sparkles:', label: ':sparkles:', image: '', unicode: '✨' },
    { value: ':balloon:', label: ':balloon:', image: '', unicode: '🎈' },
    { value: ':tada:', label: ':tada:', image: '', unicode: '🎉' },
    { value: ':confetti_ball:', label: ':confetti_ball:', image: '', unicode: '🎊' },
    { value: ':tanabata_tree:', label: ':tanabata_tree:', image: '', unicode: '🎋' },
    { value: ':bamboo:', label: ':bamboo:', image: '', unicode: '🎍' },
    { value: ':dolls:', label: ':dolls:', image: '', unicode: '🎎' },
    { value: ':flags:', label: ':flags:', image: '', unicode: '🎏' },
    { value: ':wind_chime:', label: ':wind_chime:', image: '', unicode: '🎐' },
    { value: ':rice_scene:', label: ':rice_scene:', image: '', unicode: '🎑' },
    { value: ':red_envelope:', label: ':red_envelope:', image: '', unicode: '🧧' },
    { value: ':ribbon:', label: ':ribbon:', image: '', unicode: '🎀' },
    { value: ':gift:', label: ':gift:', image: '', unicode: '🎁' },
    { value: ':reminder_ribbon:', label: ':reminder_ribbon:', image: '', unicode: '🎗️' },
    { value: ':tickets:', label: ':tickets:', image: '', unicode: '🎟️' },
    { value: ':admission_tickets:', label: ':admission_tickets:', image: '', unicode: '🎟️' },
    { value: ':ticket:', label: ':ticket:', image: '', unicode: '🎫' },
    { value: ':military_medal:', label: ':military_medal:', image: '', unicode: '🎖️' },
    { value: ':trophy:', label: ':trophy:', image: '', unicode: '🏆' },
    { value: ':medal:', label: ':medal:', image: '', unicode: '🏅' },
    { value: ':sports_medal:', label: ':sports_medal:', image: '', unicode: '🏅' },
    { value: ':first_place:', label: ':first_place:', image: '', unicode: '🥇' },
    { value: ':first_place_medal:', label: ':first_place_medal:', image: '', unicode: '🥇' },
    { value: ':second_place:', label: ':second_place:', image: '', unicode: '🥈' },
    { value: ':second_place_medal:', label: ':second_place_medal:', image: '', unicode: '🥈' },
    { value: ':third_place:', label: ':third_place:', image: '', unicode: '🥉' },
    { value: ':third_place_medal:', label: ':third_place_medal:', image: '', unicode: '🥉' },
    { value: ':soccer:', label: ':soccer:', image: '', unicode: '⚽' },
    { value: ':baseball:', label: ':baseball:', image: '', unicode: '⚾' },
    { value: ':softball:', label: ':softball:', image: '', unicode: '🥎' },
    { value: ':basketball:', label: ':basketball:', image: '', unicode: '🏀' },
    { value: ':volleyball:', label: ':volleyball:', image: '', unicode: '🏐' },
    { value: ':football:', label: ':football:', image: '', unicode: '🏈' },
    { value: ':rugby_football:', label: ':rugby_football:', image: '', unicode: '🏉' },
    { value: ':tennis:', label: ':tennis:', image: '', unicode: '🎾' },
    { value: ':flying_disc:', label: ':flying_disc:', image: '', unicode: '🥏' },
    { value: ':bowling:', label: ':bowling:', image: '', unicode: '🎳' },
    { value: ':cricket_game:', label: ':cricket_game:', image: '', unicode: '🏏' },
    { value: ':cricket_bat_ball:', label: ':cricket_bat_ball:', image: '', unicode: '🏏' },
    { value: ':field_hockey:', label: ':field_hockey:', image: '', unicode: '🏑' },
    { value: ':hockey:', label: ':hockey:', image: '', unicode: '🏒' },
    { value: ':lacrosse:', label: ':lacrosse:', image: '', unicode: '🥍' },
    { value: ':ping_pong:', label: ':ping_pong:', image: '', unicode: '🏓' },
    { value: ':table_tennis:', label: ':table_tennis:', image: '', unicode: '🏓' },
    { value: ':badminton:', label: ':badminton:', image: '', unicode: '🏸' },
    { value: ':boxing_glove:', label: ':boxing_glove:', image: '', unicode: '🥊' },
    { value: ':boxing_gloves:', label: ':boxing_gloves:', image: '', unicode: '🥊' },
    { value: ':martial_arts_uniform:', label: ':martial_arts_uniform:', image: '', unicode: '🥋' },
    { value: ':karate_uniform:', label: ':karate_uniform:', image: '', unicode: '🥋' },
    { value: ':goal_net:', label: ':goal_net:', image: '', unicode: '🥅' },
    { value: ':goal:', label: ':goal:', image: '', unicode: '🥅' },
    { value: ':golf:', label: ':golf:', image: '', unicode: '⛳' },
    { value: ':ice_skate:', label: ':ice_skate:', image: '', unicode: '⛸️' },
    { value: ':fishing_pole_and_fish:', label: ':fishing_pole_and_fish:', image: '', unicode: '🎣' },
    { value: ':diving_mask:', label: ':diving_mask:', image: '', unicode: '🤿' },
    { value: ':running_shirt_with_sash:', label: ':running_shirt_with_sash:', image: '', unicode: '🎽' },
    { value: ':ski:', label: ':ski:', image: '', unicode: '🎿' },
    { value: ':sled:', label: ':sled:', image: '', unicode: '🛷' },
    { value: ':curling_stone:', label: ':curling_stone:', image: '', unicode: '🥌' },
    { value: ':dart:', label: ':dart:', image: '', unicode: '🎯' },
    { value: ':yo_yo:', label: ':yo_yo:', image: '', unicode: '🪀' },
    { value: ':kite:', label: ':kite:', image: '', unicode: '🪁' },
    { value: ':gun:', label: ':gun:', image: '', unicode: '🔫' },
    { value: ':8ball:', label: ':8ball:', image: '', unicode: '🎱' },
    { value: ':crystal_ball:', label: ':crystal_ball:', image: '', unicode: '🔮' },
    { value: ':magic_wand:', label: ':magic_wand:', image: '', unicode: '🪄' },
    { value: ':video_game:', label: ':video_game:', image: '', unicode: '🎮' },
    { value: ':joystick:', label: ':joystick:', image: '', unicode: '🕹️' },
    { value: ':slot_machine:', label: ':slot_machine:', image: '', unicode: '🎰' },
    { value: ':game_die:', label: ':game_die:', image: '', unicode: '🎲' },
    { value: ':jigsaw:', label: ':jigsaw:', image: '', unicode: '🧩' },
    { value: ':teddy_bear:', label: ':teddy_bear:', image: '', unicode: '🧸' },
    { value: ':piñata:', label: ':piñata:', image: '', unicode: '🪅' },
    { value: ':piñata:', label: ':pinata:', image: '', unicode: '🪅' },
    { value: ':nesting_dolls:', label: ':nesting_dolls:', image: '', unicode: '🪆' },
    { value: ':spades:', label: ':spades:', image: '', unicode: '♠️' },
    { value: ':hearts:', label: ':hearts:', image: '', unicode: '♥️' },
    { value: ':diamonds:', label: ':diamonds:', image: '', unicode: '♦️' },
    { value: ':clubs:', label: ':clubs:', image: '', unicode: '♣️' },
    { value: ':chess_pawn:', label: ':chess_pawn:', image: '', unicode: '♟️' },
    { value: ':black_joker:', label: ':black_joker:', image: '', unicode: '🃏' },
    { value: ':mahjong:', label: ':mahjong:', image: '', unicode: '🀄' },
    { value: ':flower_playing_cards:', label: ':flower_playing_cards:', image: '', unicode: '🎴' },
    { value: ':performing_arts:', label: ':performing_arts:', image: '', unicode: '🎭' },
    { value: ':frame_photo:', label: ':frame_photo:', image: '', unicode: '🖼️' },
    { value: ':frame_with_picture:', label: ':frame_with_picture:', image: '', unicode: '🖼️' },
    { value: ':art:', label: ':art:', image: '', unicode: '🎨' },
    { value: ':thread:', label: ':thread:', image: '', unicode: '🧵' },
    { value: ':sewing_needle:', label: ':sewing_needle:', image: '', unicode: '🪡' },
    { value: ':yarn:', label: ':yarn:', image: '', unicode: '🧶' },
    { value: ':knot:', label: ':knot:', image: '', unicode: '🪢' },
    { value: ':eyeglasses:', label: ':eyeglasses:', image: '', unicode: '👓' },
    { value: ':dark_sunglasses:', label: ':dark_sunglasses:', image: '', unicode: '🕶️' },
    { value: ':goggles:', label: ':goggles:', image: '', unicode: '🥽' },
    { value: ':lab_coat:', label: ':lab_coat:', image: '', unicode: '🥼' },
    { value: ':safety_vest:', label: ':safety_vest:', image: '', unicode: '🦺' },
    { value: ':necktie:', label: ':necktie:', image: '', unicode: '👔' },
    { value: ':shirt:', label: ':shirt:', image: '', unicode: '👕' },
    { value: ':jeans:', label: ':jeans:', image: '', unicode: '👖' },
    { value: ':scarf:', label: ':scarf:', image: '', unicode: '🧣' },
    { value: ':gloves:', label: ':gloves:', image: '', unicode: '🧤' },
    { value: ':coat:', label: ':coat:', image: '', unicode: '🧥' },
    { value: ':socks:', label: ':socks:', image: '', unicode: '🧦' },
    { value: ':dress:', label: ':dress:', image: '', unicode: '👗' },
    { value: ':kimono:', label: ':kimono:', image: '', unicode: '👘' },
    { value: ':sari:', label: ':sari:', image: '', unicode: '🥻' },
    { value: ':one_piece_swimsuit:', label: ':one_piece_swimsuit:', image: '', unicode: '🩱' },
    { value: ':briefs:', label: ':briefs:', image: '', unicode: '🩲' },
    { value: ':shorts:', label: ':shorts:', image: '', unicode: '🩳' },
    { value: ':bikini:', label: ':bikini:', image: '', unicode: '👙' },
    { value: ':womans_clothes:', label: ':womans_clothes:', image: '', unicode: '👚' },
    { value: ':purse:', label: ':purse:', image: '', unicode: '👛' },
    { value: ':handbag:', label: ':handbag:', image: '', unicode: '👜' },
    { value: ':pouch:', label: ':pouch:', image: '', unicode: '👝' },
    { value: ':shopping:', label: ':shopping:', image: '', unicode: '🛍️' },
    { value: ':school_satchel:', label: ':school_satchel:', image: '', unicode: '🎒' },
    { value: ':thong_sandal:', label: ':thong_sandal:', image: '', unicode: '🩴' },
    { value: ':mans_shoe:', label: ':mans_shoe:', image: '', unicode: '👞' },
    { value: ':athletic_shoe:', label: ':athletic_shoe:', image: '', unicode: '👟' },
    { value: ':hiking_boot:', label: ':hiking_boot:', image: '', unicode: '🥾' },
    { value: ':womans_flat_shoe:', label: ':womans_flat_shoe:', image: '', unicode: '🥿' },
    { value: ':high_heel:', label: ':high_heel:', image: '', unicode: '👠' },
    { value: ':sandal:', label: ':sandal:', image: '', unicode: '👡' },
    { value: ':ballet_shoes:', label: ':ballet_shoes:', image: '', unicode: '🩰' },
    { value: ':boot:', label: ':boot:', image: '', unicode: '👢' },
    { value: ':crown:', label: ':crown:', image: '', unicode: '👑' },
    { value: ':womans_hat:', label: ':womans_hat:', image: '', unicode: '👒' },
    { value: ':tophat:', label: ':tophat:', image: '', unicode: '🎩' },
    { value: ':mortar_board:', label: ':mortar_board:', image: '', unicode: '🎓' },
    { value: ':billed_cap:', label: ':billed_cap:', image: '', unicode: '🧢' },
    { value: ':military_helmet:', label: ':military_helmet:', image: '', unicode: '🪖' },
    { value: ':helmet_with_cross:', label: ':helmet_with_cross:', image: '', unicode: '⛑️' },
    { value: ':helmet_with_white_cross:', label: ':helmet_with_white_cross:', image: '', unicode: '⛑️' },
    { value: ':prayer_beads:', label: ':prayer_beads:', image: '', unicode: '📿' },
    { value: ':lipstick:', label: ':lipstick:', image: '', unicode: '💄' },
    { value: ':ring:', label: ':ring:', image: '', unicode: '💍' },
    { value: ':gem:', label: ':gem:', image: '', unicode: '💎' },
    { value: ':mute:', label: ':mute:', image: '', unicode: '🔇' },
    { value: ':speaker:', label: ':speaker:', image: '', unicode: '🔈' },
    { value: ':sound:', label: ':sound:', image: '', unicode: '🔉' },
    { value: ':loud_sound:', label: ':loud_sound:', image: '', unicode: '🔊' },
    { value: ':loudspeaker:', label: ':loudspeaker:', image: '', unicode: '📢' },
    { value: ':mega:', label: ':mega:', image: '', unicode: '📣' },
    { value: ':postal_horn:', label: ':postal_horn:', image: '', unicode: '📯' },
    { value: ':bell:', label: ':bell:', image: '', unicode: '🔔' },
    { value: ':no_bell:', label: ':no_bell:', image: '', unicode: '🔕' },
    { value: ':musical_score:', label: ':musical_score:', image: '', unicode: '🎼' },
    { value: ':musical_note:', label: ':musical_note:', image: '', unicode: '🎵' },
    { value: ':notes:', label: ':notes:', image: '', unicode: '🎶' },
    { value: ':studio_microphone:', label: ':studio_microphone:', image: '', unicode: '🎙️' },
    { value: ':microphone2:', label: ':microphone2:', image: '', unicode: '🎙️' },
    { value: ':level_slider:', label: ':level_slider:', image: '', unicode: '🎚️' },
    { value: ':control_knobs:', label: ':control_knobs:', image: '', unicode: '🎛️' },
    { value: ':microphone:', label: ':microphone:', image: '', unicode: '🎤' },
    { value: ':headphones:', label: ':headphones:', image: '', unicode: '🎧' },
    { value: ':radio:', label: ':radio:', image: '', unicode: '📻' },
    { value: ':saxophone:', label: ':saxophone:', image: '', unicode: '🎷' },
    { value: ':accordion:', label: ':accordion:', image: '', unicode: '🪗' },
    { value: ':guitar:', label: ':guitar:', image: '', unicode: '🎸' },
    { value: ':musical_keyboard:', label: ':musical_keyboard:', image: '', unicode: '🎹' },
    { value: ':trumpet:', label: ':trumpet:', image: '', unicode: '🎺' },
    { value: ':violin:', label: ':violin:', image: '', unicode: '🎻' },
    { value: ':banjo:', label: ':banjo:', image: '', unicode: '🪕' },
    { value: ':drum:', label: ':drum:', image: '', unicode: '🥁' },
    { value: ':drum_with_drumsticks:', label: ':drum_with_drumsticks:', image: '', unicode: '🥁' },
    { value: ':long_drum:', label: ':long_drum:', image: '', unicode: '🪘' },
    { value: ':iphone:', label: ':iphone:', image: '', unicode: '📱' },
    { value: ':mobile_phone:', label: ':mobile_phone:', image: '', unicode: '📱' },
    { value: ':calling:', label: ':calling:', image: '', unicode: '📲' },
    { value: ':telephone:', label: 'telephone', image: '', unicode: '☎️' },
    { value: ':telephone_receiver:', label: ':telephone_receiver:', image: '', unicode: '📞' },
    { value: ':pager:', label: ':pager:', image: '', unicode: '📟' },
    { value: ':fax:', label: ':fax:', image: '', unicode: '📠' },
    { value: ':battery:', label: ':battery:', image: '', unicode: '🔋' },
    { value: ':electric_plug:', label: ':electric_plug:', image: '', unicode: '🔌' },
    { value: ':computer:', label: ':computer:', image: '', unicode: '💻' },
    { value: ':desktop_computer:', label: ':desktop_computer:', image: '', unicode: '🖥️' },
    { value: ':desktop:', label: ':desktop:', image: '', unicode: '🖥️' },
    { value: ':printer:', label: ':printer:', image: '', unicode: '🖨️' },
    { value: ':keyboard:', label: ':keyboard:', image: '', unicode: '⌨️' },
    { value: ':mouse_three_button:', label: ':mouse_three_button:', image: '', unicode: '🖱️' },
    { value: ':three_button_mouse:', label: ':three_button_mouse:', image: '', unicode: '🖱️' },
    { value: ':trackball:', label: ':trackball:', image: '', unicode: '🖲️' },
    { value: ':minidisc:', label: ':minidisc:', image: '', unicode: '💽' },
    { value: ':floppy_disk:', label: ':floppy_disk:', image: '', unicode: '💾' },
    { value: ':cd:', label: ':cd:', image: '', unicode: '💿' },
    { value: ':dvd:', label: ':dvd:', image: '', unicode: '📀' },
    { value: ':abacus:', label: ':abacus:', image: '', unicode: '🧮' },
    { value: ':movie_camera:', label: ':movie_camera:', image: '', unicode: '🎥' },
    { value: ':film_frames:', label: ':film_frames:', image: '', unicode: '🎞️' },
    { value: ':film_projector:', label: ':film_projector:', image: '', unicode: '📽️' },
    { value: ':projector:', label: ':projector:', image: '', unicode: '📽️' },
    { value: ':clapper:', label: ':clapper:', image: '', unicode: '🎬' },
    { value: ':tv:', label: ':tv:', image: '', unicode: '📺' },
    { value: ':camera:', label: ':camera:', image: '', unicode: '📷' },
    { value: ':camera_with_flash:', label: ':camera_with_flash:', image: '', unicode: '📸' },
    { value: ':video_camera:', label: ':video_camera:', image: '', unicode: '📹' },
    { value: ':vhs:', label: ':vhs:', image: '', unicode: '📼' },
    { value: ':mag:', label: ':mag:', image: '', unicode: '🔍' },
    { value: ':mag_right:', label: ':mag_right:', image: '', unicode: '🔎' },
    { value: ':candle:', label: ':candle:', image: '', unicode: '🕯️' },
    { value: ':bulb:', label: ':bulb:', image: '', unicode: '💡' },
    { value: ':flashlight:', label: ':flashlight:', image: '', unicode: '🔦' },
    { value: ':izakaya_lantern:', label: ':izakaya_lantern:', image: '', unicode: '🏮' },
    { value: ':diya_lamp:', label: ':diya_lamp:', image: '', unicode: '🪔' },
    { value: ':notebook_with_decorative_cover:', label: ':notebook_with_decorative_cover:', image: '', unicode: '📔' },
    { value: ':closed_book:', label: ':closed_book:', image: '', unicode: '📕' },
    { value: ':book:', label: ':book:', image: '', unicode: '📖' },
    { value: ':green_book:', label: ':green_book:', image: '', unicode: '📗' },
    { value: ':blue_book:', label: ':blue_book:', image: '', unicode: '📘' },
    { value: ':orange_book:', label: ':orange_book:', image: '', unicode: '📙' },
    { value: ':books:', label: ':books:', image: '', unicode: '📚' },
    { value: ':notebook:', label: ':notebook:', image: '', unicode: '📓' },
    { value: ':ledger:', label: ':ledger:', image: '', unicode: '📒' },
    { value: ':page_with_curl:', label: ':page_with_curl:', image: '', unicode: '📃' },
    { value: ':scroll:', label: ':scroll:', image: '', unicode: '📜' },
    { value: ':page_facing_up:', label: ':page_facing_up:', image: '', unicode: '📄' },
    { value: ':newspaper:', label: ':newspaper:', image: '', unicode: '📰' },
    { value: ':newspaper2:', label: ':newspaper2:', image: '', unicode: '🗞️' },
    { value: ':rolled_up_newspaper:', label: ':rolled_up_newspaper:', image: '', unicode: '🗞️' },
    { value: ':bookmark_tabs:', label: ':bookmark_tabs:', image: '', unicode: '📑' },
    { value: ':bookmark:', label: ':bookmark:', image: '', unicode: '🔖' },
    { value: ':label:', label: ':label:', image: '', unicode: '🏷️' },
    { value: ':moneybag:', label: ':moneybag:', image: '', unicode: '💰' },
    { value: ':coin:', label: ':coin:', image: '', unicode: '🪙' },
    { value: ':yen:', label: ':yen:', image: '', unicode: '💴' },
    { value: ':dollar:', label: ':dollar:', image: '', unicode: '💵' },
    { value: ':euro:', label: ':euro:', image: '', unicode: '💶' },
    { value: ':pound:', label: ':pound:', image: '', unicode: '💷' },
    { value: ':money_with_wings:', label: ':money_with_wings:', image: '', unicode: '💸' },
    { value: ':credit_card:', label: ':credit_card:', image: '', unicode: '💳' },
    { value: ':receipt:', label: ':receipt:', image: '', unicode: '🧾' },
    { value: ':chart:', label: ':chart:', image: '', unicode: '💹' },
    { value: ':envelope:', label: ':envelope:', image: '', unicode: '✉️' },
    { value: ':email:', label: ':email:', image: '', unicode: '📧' },
    { value: ':e-mail:', label: ':e-mail:', image: '', unicode: '📧' },
    { value: ':incoming_envelope:', label: ':incoming_envelope:', image: '', unicode: '📨' },
    { value: ':envelope_with_arrow:', label: ':envelope_with_arrow:', image: '', unicode: '📩' },
    { value: ':outbox_tray:', label: ':outbox_tray:', image: '', unicode: '📤' },
    { value: ':inbox_tray:', label: ':inbox_tray:', image: '', unicode: '📥' },
    { value: ':package:', label: ':package:', image: '', unicode: '📦' },
    { value: ':mailbox:', label: ':mailbox:', image: '', unicode: '📫' },
    { value: ':mailbox_closed:', label: ':mailbox_closed:', image: '', unicode: '📪' },
    { value: ':mailbox_with_mail:', label: ':mailbox_with_mail:', image: '', unicode: '📬' },
    { value: ':mailbox_with_no_mail:', label: ':mailbox_with_no_mail:', image: '', unicode: '📭' },
    { value: ':postbox:', label: ':postbox:', image: '', unicode: '📮' },
    { value: ':ballot_box:', label: ':ballot_box:', image: '', unicode: '🗳️' },
    { value: ':ballot_box_with_ballot:', label: ':ballot_box_with_ballot:', image: '', unicode: '🗳️' },
    { value: ':pencil2:', label: ':pencil2:', image: '', unicode: '✏️' },
    { value: ':black_nib:', label: ':black_nib:', image: '', unicode: '✒️' },
    { value: ':fountain_pen:', label: ':fountain_pen:', image: '', unicode: '🖋️' },
    { value: ':lower_left_fountain_pen:', label: ':lower_left_fountain_pen:', image: '', unicode: '🖋️' },
    { value: ':pen_ballpoint:', label: ':pen_ballpoint:', image: '', unicode: '🖊️' },
    { value: ':lower_left_ballpoint_pen:', label: ':lower_left_ballpoint_pen:', image: '', unicode: '🖊️' },
    { value: ':paintbrush:', label: ':paintbrush:', image: '', unicode: '🖌️' },
    { value: ':lower_left_paintbrush:', label: ':lower_left_paintbrush:', image: '', unicode: '🖌️' },
    { value: ':crayon:', label: ':crayon:', image: '', unicode: '🖍️' },
    { value: ':lower_left_crayon:', label: ':lower_left_crayon:', image: '', unicode: '🖍️' },
    { value: ':memo:', label: ':memo:', image: '', unicode: '📝' },
    { value: ':pencil:', label: ':pencil:', image: '', unicode: '📝' },
    { value: ':briefcase:', label: ':briefcase:', image: '', unicode: '💼' },
    { value: ':file_folder:', label: ':file_folder:', image: '', unicode: '📁' },
    { value: ':open_file_folder:', label: ':open_file_folder:', image: '', unicode: '📂' },
    { value: ':card_index_dividers:', label: ':card_index_dividers:', image: '', unicode: '🗂️' },
    { value: ':dividers:', label: ':dividers:', image: '', unicode: '🗂️' },
    { value: ':date:', label: ':date:', image: '', unicode: '📅' },
    { value: ':calendar:', label: ':calendar:', image: '', unicode: '📆' },
    { value: ':notepad_spiral:', label: ':notepad_spiral:', image: '', unicode: '🗒️' },
    { value: ':spiral_note_pad:', label: ':spiral_note_pad:', image: '', unicode: '🗒️' },
    { value: ':spiral_calendar_pad:', label: ':spiral_calendar_pad:', image: '', unicode: '🗓️' },
    { value: ':calender_spiral:', label: ':calender_spiral:', image: '', unicode: '🗓️' },
    { value: ':card_index:', label: ':card_index:', image: '', unicode: '📇' },
    { value: ':chart_with_upwards_trend:', label: ':chart_with_upwards_trend:', image: '', unicode: '📈' },
    { value: ':chart_with_downwards_trend:', label: ':chart_with_downwards_trend:', image: '', unicode: '📉' },
    { value: ':bar_chart:', label: ':bar_chart:', image: '', unicode: '📊' },
    { value: ':clipboard:', label: ':clipboard:', image: '', unicode: '📋' },
    { value: ':pushpin:', label: ':pushpin:', image: '', unicode: '📌' },
    { value: ':round_pushpin:', label: ':round_pushpin:', image: '', unicode: '📍' },
    { value: ':paperclip:', label: ':paperclip:', image: '', unicode: '📎' },
    { value: ':paperclips:', label: ':paperclips:', image: '', unicode: '🖇️' },
    { value: ':linked_paperclips:', label: ':linked_paperclips:', image: '', unicode: '🖇️' },
    { value: ':straight_ruler:', label: ':straight_ruler:', image: '', unicode: '📏' },
    { value: ':triangular_ruler:', label: ':triangular_ruler:', image: '', unicode: '📐' },
    { value: ':scissors:', label: ':scissors:', image: '', unicode: '✂️' },
    { value: ':card_file_box:', label: ':card_file_box:', image: '', unicode: '🗃️' },
    { value: ':card_file:', label: ':card_file:', image: '', unicode: '🗃️' },
    { value: ':file_cabinet:', label: ':file_cabinet:', image: '', unicode: '🗄️' },
    { value: ':wastebasket:', label: ':wastebasket:', image: '', unicode: '🗑️' },
    { value: ':lock:', label: ':lock:', image: '', unicode: '🔒' },
    { value: ':unlock:', label: ':unlock:', image: '', unicode: '🔓' },
    { value: ':lock_with_ink_pen:', label: ':lock_with_ink_pen:', image: '', unicode: '🔏' },
    { value: ':closed_lock_with_key:', label: ':closed_lock_with_key:', image: '', unicode: '🔐' },
    { value: ':key:', label: ':key:', image: '', unicode: '🔑' },
    { value: ':old_key:', label: ':old_key:', image: '', unicode: '🗝️' },
    { value: ':key2:', label: ':key2:', image: '', unicode: '🗝️' },
    { value: ':hammer:', label: ':hammer:', image: '', unicode: '🔨' },
    { value: ':axe:', label: ':axe:', image: '', unicode: '🪓' },
    { value: ':pick:', label: ':pick:', image: '', unicode: '⛏️' },
    { value: ':hammer_and_pick:', label: ':hammer_and_pick:', image: '', unicode: '⚒️' },
    { value: ':hammer_pick:', label: ':hammer_pick:', image: '', unicode: '⚒️' },
    { value: ':hammer_and_wrench:', label: ':hammer_and_wrench:', image: '', unicode: '🛠️' },
    { value: ':tools:', label: ':tools:', image: '', unicode: '🛠️' },
    { value: ':dagger:', label: ':dagger:', image: '', unicode: '🗡️' },
    { value: ':dagger:_knife>', label: ':dagger_knife:', image: '', unicode: '🗡️' },
    { value: ':crossed_swords:', label: ':crossed_swords:', image: '', unicode: '⚔️' },
    { value: ':bomb:', label: ':bomb:', image: '', unicode: '💣' },
    { value: ':boomerang:', label: ':boomerang:', image: '', unicode: '🪃' },
    { value: ':bow_and_arrow:', label: ':bow_and_arrow:', image: '', unicode: '🏹' },
    { value: ':archery:', label: ':archery:', image: '', unicode: '🏹' },
    { value: ':shield:', label: ':shield:', image: '', unicode: '🛡️' },
    { value: ':carpentry_saw:', label: ':carpentry_saw:', image: '', unicode: '🪚' },
    { value: ':wrench:', label: ':wrench:', image: '', unicode: '🔧' },
    { value: ':screwdriver:', label: ':screwdriver:', image: '', unicode: '🪛' },
    { value: ':nut_and_bolt:', label: ':nut_and_bolt:', image: '', unicode: '🔩' },
    { value: ':gear:', label: ':gear:', image: '', unicode: '⚙️' },
    { value: ':compression:', label: ':compression:', image: '', unicode: '🗜️' },
    { value: ':scales:', label: ':scales:', image: '', unicode: '⚖️' },
    { value: ':probing_cane:', label: ':probing_cane:', image: '', unicode: '🦯' },
    { value: ':link:', label: ':link:', image: '', unicode: '🔗' },
    { value: ':chains:', label: ':chains:', image: '', unicode: '⛓️' },
    { value: ':hook:', label: ':hook:', image: '', unicode: '🪝' },
    { value: ':toolbox:', label: ':toolbox:', image: '', unicode: '🧰' },
    { value: ':magnet:', label: ':magnet:', image: '', unicode: '🧲' },
    { value: ':ladder:', label: ':ladder:', image: '', unicode: '🪜' },
    { value: ':alembic:', label: ':alembic:', image: '', unicode: '⚗️' },
    { value: ':test_tube:', label: ':test_tube:', image: '', unicode: '🧪' },
    { value: ':petri_dish:', label: ':petri_dish:', image: '', unicode: '🧫' },
    { value: ':dna:', label: ':dna:', image: '', unicode: '🧬' },
    { value: ':microscope:', label: ':microscope:', image: '', unicode: '🔬' },
    { value: ':telescope:', label: ':telescope:', image: '', unicode: '🔭' },
    { value: ':satellite:', label: ':satellite:', image: '', unicode: '📡' },
    { value: ':syringe:', label: ':syringe:', image: '', unicode: '💉' },
    { value: ':drop_of_blood:', label: ':drop_of_blood:', image: '', unicode: '🩸' },
    { value: ':pill:', label: ':pill:', image: '', unicode: '💊' },
    { value: ':adhesive_bandage:', label: ':adhesive_bandage:', image: '', unicode: '🩹' },
    { value: ':stethoscope:', label: ':stethoscope:', image: '', unicode: '🩺' },
    { value: ':door:', label: ':door:', image: '', unicode: '🚪' },
    { value: ':elevator:', label: ':elevator:', image: '', unicode: '🛗' },
    { value: ':mirror:', label: ':mirror:', image: '', unicode: '🪞' },
    { value: ':window:', label: ':window:', image: '', unicode: '🪟' },
    { value: ':bed:', label: ':bed:', image: '', unicode: '🛏️' },
    { value: ':couch_and_lamp:', label: ':couch_and_lamp:', image: '', unicode: '🛋️' },
    { value: ':couch:', label: ':couch:', image: '', unicode: '🛋️' },
    { value: ':chair:', label: ':chair:', image: '', unicode: '🪑' },
    { value: ':toilet:', label: ':toilet:', image: '', unicode: '🚽' },
    { value: ':plunger:', label: ':plunger:', image: '', unicode: '🪠' },
    { value: ':shower:', label: ':shower:', image: '', unicode: '🚿' },
    { value: ':bathtub:', label: ':bathtub:', image: '', unicode: '🛁' },
    { value: ':mouse_trap:', label: ':mouse_trap:', image: '', unicode: '🪤' },
    { value: ':razor:', label: ':razor:', image: '', unicode: '🪒' },
    { value: ':squeeze_bottle:', label: ':squeeze_bottle:', image: '', unicode: '🧴' },
    { value: ':safety_pin:', label: ':safety_pin:', image: '', unicode: '🧷' },
    { value: ':broom:', label: ':broom:', image: '', unicode: '🧹' },
    { value: ':basket:', label: ':basket:', image: '', unicode: '🧺' },
    { value: ':roll_of_paper:', label: ':roll_of_paper:', image: '', unicode: '🧻' },
    { value: ':bucket:', label: ':bucket:', image: '', unicode: '🪣' },
    { value: ':soap:', label: ':soap:', image: '', unicode: '🧼' },
    { value: ':toothbrush:', label: ':toothbrush:', image: '', unicode: '🪥' },
    { value: ':sponge:', label: ':sponge:', image: '', unicode: '🧽' },
    { value: ':fire_extinguisher:', label: ':fire_extinguisher:', image: '', unicode: '🧯' },
    { value: ':shopping_cart:', label: ':shopping_cart:', image: '', unicode: '🛒' },
    { value: ':shopping_trolley:', label: ':shopping_trolley:', image: '', unicode: '🛒' },
    { value: ':smoking:', label: ':smoking:', image: '', unicode: '🚬' },
    { value: ':coffin:', label: ':coffin:', image: '', unicode: '⚰️' },
    { value: ':headstone:', label: ':headstone:', image: '', unicode: '🪦' },
    { value: ':funeral_urn:', label: ':funeral_urn:', image: '', unicode: '⚱️' },
    { value: ':urn:', label: ':urn:', image: '', unicode: '⚱️' },
    { value: ':nazar_amulet:', label: ':nazar_amulet:', image: '', unicode: '🧿' },
    { value: ':moyai:', label: ':moyai:', image: '', unicode: '🗿' },
    { value: ':placard:', label: ':placard:', image: '', unicode: '🪧' },
    { value: ':atm:', label: ':atm:', image: '', unicode: '🏧' },
    { value: ':put_litter_in_its_place:', label: ':put_litter_in_its_place:', image: '', unicode: '🚮' },
    { value: ':potable_water:', label: ':potable_water:', image: '', unicode: '🚰' },
    { value: ':wheelchair:', label: ':wheelchair:', image: '', unicode: '♿' },
    { value: ':mens:', label: ':mens:', image: '', unicode: '🚹' },
    { value: ':womens:', label: ':womens:', image: '', unicode: '🚺' },
    { value: ':restroom:', label: ':restroom:', image: '', unicode: '🚻' },
    { value: ':baby_symbol:', label: ':baby_symbol:', image: '', unicode: '🚼' },
    { value: ':wc:', label: ':wc:', image: '', unicode: '🚾' },
    { value: ':passport_control:', label: ':passport_control:', image: '', unicode: '🛂' },
    { value: ':customs:', label: ':customs:', image: '', unicode: '🛃' },
    { value: ':baggage_claim:', label: ':baggage_claim:', image: '', unicode: '🛄' },
    { value: ':left_luggage:', label: ':left_luggage:', image: '', unicode: '🛅' },
    { value: ':warning:', label: ':warning:', image: '', unicode: '⚠️' },
    { value: ':children_crossing:', label: ':children_crossing:', image: '', unicode: '🚸' },
    { value: ':no_entry:', label: ':no_entry:', image: '', unicode: '⛔' },
    { value: ':no_entry_sign:', label: ':no_entry_sign:', image: '', unicode: '🚫' },
    { value: ':no_bicycles:', label: ':no_bicycles:', image: '', unicode: '🚳' },
    { value: ':no_smoking:', label: ':no_smoking:', image: '', unicode: '🚭' },
    { value: ':do_not_litter:', label: ':do_not_litter:', image: '', unicode: '🚯' },
    { value: ':non_potable_water:', label: ':non_potable_water:', image: '', unicode: '🚱' },
    { value: ':no_pedestrians:', label: ':no_pedestrians:', image: '', unicode: '🚷' },
    { value: ':no_mobile_phones:', label: ':no_mobile_phones:', image: '', unicode: '📵' },
    { value: ':underage:', label: ':underage:', image: '', unicode: '🔞' },
    { value: ':radioactive:', label: ':radioactive:', image: '', unicode: '☢️' },
    { value: ':radioactive_sign:', label: ':radioactive_sign:', image: '', unicode: '☢️' },
    { value: ':biohazard:', label: ':biohazard:', image: '', unicode: '☣️' },
    { value: ':biohazard_sign:', label: ':biohazard_sign:', image: '', unicode: '☣️' },
    { value: ':arrow_up:', label: ':arrow_up:', image: '', unicode: '⬆️' },
    { value: ':arrow_upper_right:', label: ':arrow_upper_right:', image: '', unicode: '↗️' },
    { value: ':arrow_right:', label: ':arrow_right:', image: '', unicode: '➡️' },
    { value: ':arrow_lower_right:', label: ':arrow_lower_right:', image: '', unicode: '↘️' },
    { value: ':arrow_down:', label: ':arrow_down:', image: '', unicode: '⬇️' },
    { value: ':arrow_lower_left:', label: ':arrow_lower_left:', image: '', unicode: '↙️' },
    { value: ':arrow_left:', label: ':arrow_left:', image: '', unicode: '⬅️' },
    { value: ':arrow_upper_left:', label: ':arrow_upper_left:', image: '', unicode: '↖️' },
    { value: ':arrow_up_down:', label: ':arrow_up_down:', image: '', unicode: '↕️' },
    { value: ':left_right_arrow:', label: ':left_right_arrow:', image: '', unicode: '↔️' },
    { value: ':leftwards_arrow_with_hook:', label: ':leftwards_arrow_with_hook:', image: '', unicode: '↩️' },
    { value: ':arrow_right_hook:', label: ':arrow_right_hook:', image: '', unicode: '↪️' },
    { value: ':arrow_heading_up:', label: ':arrow_heading_up:', image: '', unicode: '⤴️' },
    { value: ':arrow_heading_down:', label: ':arrow_heading_down:', image: '', unicode: '⤵️' },
    { value: ':arrows_clockwise:', label: ':arrows_clockwise:', image: '', unicode: '🔃' },
    { value: ':arrows_counterclockwise:', label: ':arrows_counterclockwise:', image: '', unicode: '🔄' },
    { value: ':back:', label: ':back:', image: '', unicode: '🔙' },
    { value: ':end:', label: ':end:', image: '', unicode: '🔚' },
    { value: ':on:', label: ':on:', image: '', unicode: '🔛' },
    { value: ':soon:', label: ':soon:', image: '', unicode: '🔜' },
    { value: ':top:', label: ':top:', image: '', unicode: '🔝' },
    { value: ':place_of_worship:', label: ':place_of_worship:', image: '', unicode: '🛐' },
    { value: ':worship_symbol:', label: ':worship_symbol:', image: '', unicode: '🛐' },
    { value: ':atom_symbol:', label: ':atom_symbol:', image: '', unicode: '⚛️' },
    { value: ':atom:', label: ':atom:', image: '', unicode: '⚛️' },
    { value: ':om_symbol:', label: ':om_symbol:', image: '', unicode: '🕉️' },
    { value: ':star_of_david:', label: ':star_of_david:', image: '', unicode: '✡️' },
    { value: ':wheel_of_dharma:', label: ':wheel_of_dharma:', image: '', unicode: '☸️' },
    { value: ':yin_yang:', label: ':yin_yang:', image: '', unicode: '☯️' },
    { value: ':latin_cross:', label: ':latin_cross:', image: '', unicode: '✝️' },
    { value: ':cross:', label: ':cross:', image: '', unicode: '✝️' },
    { value: ':orthodox_cross:', label: ':orthodox_cross:', image: '', unicode: '☦️' },
    { value: ':star_and_crescent:', label: ':star_and_crescent:', image: '', unicode: '☪️' },
    { value: ':peace_symbol:', label: ':peace_symbol:', image: '', unicode: '☮️' },
    { value: ':peace:', label: ':peace:', image: '', unicode: '☮️' },
    { value: ':menorah:', label: ':menorah:', image: '', unicode: '🕎' },
    { value: ':six_pointed_star:', label: ':six_pointed_star:', image: '', unicode: '🔯' },
    { value: ':aries:', label: ':aries:', image: '', unicode: '♈' },
    { value: ':taurus:', label: ':taurus:', image: '', unicode: '♉' },
    { value: ':gemini:', label: ':gemini:', image: '', unicode: '♊' },
    { value: ':cancer:', label: ':cancer:', image: '', unicode: '♋' },
    { value: ':leo:', label: ':leo:', image: '', unicode: '♌' },
    { value: ':virgo:', label: ':virgo:', image: '', unicode: '♍' },
    { value: ':libra:', label: ':libra:', image: '', unicode: '♎' },
    { value: ':scorpius:', label: ':scorpius:', image: '', unicode: '♏' },
    { value: ':sagittarius:', label: ':sagittarius:', image: '', unicode: '♐' },
    { value: ':capricorn:', label: ':capricorn:', image: '', unicode: '♑' },
    { value: ':aquarius:', label: ':aquarius:', image: '', unicode: '♒' },
    { value: ':pisces:', label: ':pisces:', image: '', unicode: '♓' },
    { value: ':ophiuchus:', label: ':ophiuchus:', image: '', unicode: '⛎' },
    { value: ':twisted_rightwards_arrows:', label: ':twisted_rightwards_arrows:', image: '', unicode: '🔀' },
    { value: ':repeat:', label: ':repeat:', image: '', unicode: '🔁' },
    { value: ':repeat_one:', label: ':repeat_one:', image: '', unicode: '🔂' },
    { value: ':arrow_forward:', label: ':arrow_forward:', image: '', unicode: '▶️' },
    { value: ':fast_forward:', label: ':fast_forward:', image: '', unicode: '⏩' },
    { value: ':next_track:', label: ':next_track:', image: '', unicode: '⏭️' },
    { value: ':track_next:', label: ':track_next:', image: '', unicode: '⏭️' },
    { value: ':play_pause:', label: ':play_pause:', image: '', unicode: '⏯️' },
    { value: ':arrow_backward:', label: ':arrow_backward:', image: '', unicode: '◀️' },
    { value: ':rewind:', label: ':rewind:', image: '', unicode: '⏪' },
    { value: ':previous_track:', label: ':previous_track:', image: '', unicode: '⏮️' },
    { value: ':track_previous:', label: ':track_previous:', image: '', unicode: '⏮️' },
    { value: ':arrow_up_small:', label: ':arrow_up_small:', image: '', unicode: '🔼' },
    { value: ':arrow_double_up:', label: ':arrow_double_up:', image: '', unicode: '⏫' },
    { value: ':arrow_down_small:', label: ':arrow_down_small:', image: '', unicode: '🔽' },
    { value: ':arrow_double_down:', label: ':arrow_double_down:', image: '', unicode: '⏬' },
    { value: ':pause_button:', label: ':pause_button:', image: '', unicode: '⏸️' },
    { value: ':double_vertical_bar:', label: ':double_vertical_bar:', image: '', unicode: '⏸️' },
    { value: ':stop_button:', label: ':stop_button:', image: '', unicode: '⏹️' },
    { value: ':record_button:', label: ':record_button:', image: '', unicode: '⏺️' },
    { value: ':eject:', label: ':eject:', image: '', unicode: '⏏️' },
    { value: ':eject_symbol:', label: ':eject_symbol:', image: '', unicode: '⏏️' },
    { value: ':cinema:', label: ':cinema:', image: '', unicode: '🎦' },
    { value: ':low_brightness:', label: ':low_brightness:', image: '', unicode: '🔅' },
    { value: ':high_brightness:', label: ':high_brightness:', image: '', unicode: '🔆' },
    { value: ':signal_strength:', label: ':signal_strength:', image: '', unicode: '📶' },
    { value: ':vibration_mode:', label: ':vibration_mode:', image: '', unicode: '📳' },
    { value: ':mobile_phone_off:', label: ':mobile_phone_off:', image: '', unicode: '📴' },
    { value: ':female_sign:', label: ':female_sign:', image: '', unicode: '♀️' },
    { value: ':male_sign:', label: ':male_sign:', image: '', unicode: '♂️' },
    { value: ':transgender_symbol:', label: ':transgender_symbol:', image: '', unicode: '⚧️' },
    { value: ':heavy_multiplication_x:', label: ':heavy_multiplication_x:', image: '', unicode: '✖️' },
    { value: ':heavy_plus_sign:', label: ':heavy_plus_sign:', image: '', unicode: '➕' },
    { value: ':heavy_minus_sign:', label: ':heavy_minus_sign:', image: '', unicode: '➖' },
    { value: ':heavy_division_sign:', label: ':heavy_division_sign:', image: '', unicode: '➗' },
    { value: ':infinity:', label: ':infinity:', image: '', unicode: '♾️' },
    { value: ':bangbang:', label: ':bangbang:', image: '', unicode: '‼️' },
    { value: ':interrobang:', label: ':interrobang:', image: '', unicode: '⁉️' },
    { value: ':question:', label: ':question:', image: '', unicode: '❓' },
    { value: ':grey_question:', label: ':grey_question:', image: '', unicode: '❔' },
    { value: ':grey_exclamation:', label: ':grey_exclamation:', image: '', unicode: '❕' },
    { value: ':exclamation:', label: ':exclamation:', image: '', unicode: '❗' },
    { value: ':wavy_dash:', label: ':wavy_dash:', image: '', unicode: '〰️' },
    { value: ':currency_exchange:', label: ':currency_exchange:', image: '', unicode: '💱' },
    { value: ':heavy_dollar_sign:', label: ':heavy_dollar_sign:', image: '', unicode: '💲' },
    { value: ':medical_symbol:', label: ':medical_symbol:', image: '', unicode: '⚕️' },
    { value: ':recycle:', label: ':recycle:', image: '', unicode: '♻️' },
    { value: ':fleur_de_lis:', label: ':fleur_de_lis:', image: '', unicode: '⚜️' },
    { value: ':trident:', label: ':trident:', image: '', unicode: '🔱' },
    { value: ':name_badge:', label: ':name_badge:', image: '', unicode: '📛' },
    { value: ':beginner:', label: ':beginner:', image: '', unicode: '🔰' },
    { value: ':o:', label: ':o:', image: '', unicode: '⭕' },
    { value: ':white_check_mark:', label: ':white_check_mark:', image: '', unicode: '✅' },
    { value: ':ballot_box_with_check:', label: ':ballot_box_with_check:', image: '', unicode: '☑️' },
    { value: ':heavy_check_mark:', label: ':heavy_check_mark:', image: '', unicode: '✔️' },
    { value: ':x:', label: ':x:', image: '', unicode: '❌' },
    { value: ':negative_squared_cross_mark:', label: ':negative_squared_cross_mark:', image: '', unicode: '❎' },
    { value: ':curly_loop:', label: ':curly_loop:', image: '', unicode: '➰' },
    { value: ':loop:', label: ':loop:', image: '', unicode: '➿' },
    { value: ':part_alternation_mark:', label: ':part_alternation_mark:', image: '', unicode: '〽️' },
    { value: ':eight_spoked_asterisk:', label: ':eight_spoked_asterisk:', image: '', unicode: '✳️' },
    { value: ':eight_pointed_black_star:', label: ':eight_pointed_black_star:', image: '', unicode: '✴️' },
    { value: ':sparkle:', label: ':sparkle:', image: '', unicode: '❇️' },
    { value: ':copyright:', label: ':copyright:', image: '', unicode: '©️' },
    { value: ':registered:', label: ':registered:', image: '', unicode: '®️' },
    { value: ':tm:', label: ':tm:', image: '', unicode: '™️' },
    { value: ':hash:', label: ':hash:', image: '', unicode: '#️⃣' },
    { value: ':asterisk:', label: ':asterisk:', image: '', unicode: '*️⃣' },
    { value: ':keycap_asterisk:', label: ':keycap_asterisk:', image: '', unicode: '*️⃣' },
    { value: ':zero:', label: ':zero:', image: '', unicode: '0️⃣' },
    { value: ':one:', label: ':one:', image: '', unicode: '1️⃣' },
    { value: ':two:', label: ':two:', image: '', unicode: '2️⃣' },
    { value: ':three:', label: ':three:', image: '', unicode: '3️⃣' },
    { value: ':four:', label: ':four:', image: '', unicode: '4️⃣' },
    { value: ':five:', label: ':five:', image: '', unicode: '5️⃣' },
    { value: ':six:', label: ':six:', image: '', unicode: '6️⃣' },
    { value: ':seven:', label: ':seven:', image: '', unicode: '7️⃣' },
    { value: ':eight:', label: ':eight:', image: '', unicode: '8️⃣' },
    { value: ':nine:', label: ':nine:', image: '', unicode: '9️⃣' },
    { value: ':keycap_ten:', label: ':keycap_ten:', image: '', unicode: '🔟' },
    { value: ':capital_abcd:', label: ':capital_abcd:', image: '', unicode: '🔠' },
    { value: ':abcd:', label: ':abcd:', image: '', unicode: '🔡' },
    { value: ':1234:', label: ':1234:', image: '', unicode: '🔢' },
    { value: ':symbols:', label: ':symbols:', image: '', unicode: '🔣' },
    { value: ':abc:', label: ':abc:', image: '', unicode: '🔤' },
    { value: ':a:', label: ':a:', image: '', unicode: '🅰️' },
    { value: ':ab:', label: ':ab:', image: '', unicode: '🆎' },
    { value: ':b:', label: ':b:', image: '', unicode: '🅱️' },
    { value: ':cl:', label: ':cl:', image: '', unicode: '🆑' },
    { value: ':cool:', label: ':cool:', image: '', unicode: '🆒' },
    { value: ':free:', label: ':free:', image: '', unicode: '🆓' },
    { value: ':information_source:', label: ':information_source:', image: '', unicode: 'ℹ️' },
    { value: ':id:', label: ':id:', image: '', unicode: '🆔' },
    { value: ':m:', label: ':m:', image: '', unicode: 'Ⓜ️' },
    { value: ':new:', label: ':new:', image: '', unicode: '🆕' },
    { value: ':ng:', label: ':ng:', image: '', unicode: '🆖' },
    { value: ':o2:', label: ':o2:', image: '', unicode: '🅾️' },
    { value: ':ok:', label: ':ok:', image: '', unicode: '🆗' },
    { value: ':parking:', label: ':parking:', image: '', unicode: '🅿️' },
    { value: ':sos:', label: ':sos:', image: '', unicode: '🆘' },
    { value: ':up:', label: ':up:', image: '', unicode: '🆙' },
    { value: ':vs:', label: ':vs:', image: '', unicode: '🆚' },
    { value: ':koko:', label: ':koko:', image: '', unicode: '🈁' },
    { value: ':sa:', label: ':sa:', image: '', unicode: '🈂️' },
    { value: ':u6708:', label: ':u6708:', image: '', unicode: '🈷️' },
    { value: ':u6709:', label: ':u6709:', image: '', unicode: '🈶' },
    { value: ':u6307:', label: ':u6307:', image: '', unicode: '🈯' },
    { value: ':ideograph_advantage:', label: ':ideograph_advantage:', image: '', unicode: '🉐' },
    { value: ':u5272:', label: ':u5272:', image: '', unicode: '🈹' },
    { value: ':u7121:', label: ':u7121:', image: '', unicode: '🈚' },
    { value: ':u7981:', label: ':u7981:', image: '', unicode: '🈲' },
    { value: ':accept:', label: ':accept:', image: '', unicode: '🉑' },
    { value: ':u7533:', label: ':u7533:', image: '', unicode: '🈸' },
    { value: ':u5408:', label: ':u5408:', image: '', unicode: '🈴' },
    { value: ':u7a7a:', label: ':u7a7a:', image: '', unicode: '🈳' },
    { value: ':congratulations:', label: ':congratulations:', image: '', unicode: '㊗️' },
    { value: ':secret:', label: ':secret:', image: '', unicode: '㊙️' },
    { value: ':u55b6:', label: ':u55b6:', image: '', unicode: '🈺' },
    { value: ':u6e80:', label: ':u6e80:', image: '', unicode: '🈵' },
    { value: ':red_circle:', label: ':red_circle:', image: '', unicode: '🔴' },
    { value: ':orange_circle:', label: ':orange_circle:', image: '', unicode: '🟠' },
    { value: ':yellow_circle:', label: ':yellow_circle:', image: '', unicode: '🟡' },
    { value: ':green_circle:', label: ':green_circle:', image: '', unicode: '🟢' },
    { value: ':blue_circle:', label: ':blue_circle:', image: '', unicode: '🔵' },
    { value: ':purple_circle:', label: ':purple_circle:', image: '', unicode: '🟣' },
    { value: ':brown_circle:', label: ':brown_circle:', image: '', unicode: '🟤' },
    { value: ':black_circle:', label: ':black_circle:', image: '', unicode: '⚫' },
    { value: ':white_circle:', label: ':white_circle:', image: '', unicode: '⚪' },
    { value: ':red_square:', label: ':red_square:', image: '', unicode: '🟥' },
    { value: ':orange_square:', label: ':orange_square:', image: '', unicode: '🟧' },
    { value: ':yellow_square:', label: ':yellow_square:', image: '', unicode: '🟨' },
    { value: ':green_square:', label: ':green_square:', image: '', unicode: '🟩' },
    { value: ':blue_square:', label: ':blue_square:', image: '', unicode: '🟦' },
    { value: ':purple_square:', label: ':purple_square:', image: '', unicode: '🟪' },
    { value: ':brown_square:', label: ':brown_square:', image: '', unicode: '🟫' },
    { value: ':black_large_square:', label: ':black_large_square:', image: '', unicode: '⬛' },
    { value: ':white_large_square:', label: ':white_large_square:', image: '', unicode: '⬜' },
    { value: ':black_medium_square:', label: ':black_medium_square:', image: '', unicode: '◼️' },
    { value: ':white_medium_square:', label: ':white_medium_square:', image: '', unicode: '◻️' },
    { value: ':black_medium_small_square:', label: ':black_medium_small_square:', image: '', unicode: '◾' },
    { value: ':white_medium_small_square:', label: ':white_medium_small_square:', image: '', unicode: '◽' },
    { value: ':black_small_square:', label: ':black_small_square:', image: '', unicode: '▪️' },
    { value: ':white_small_square:', label: ':white_small_square:', image: '', unicode: '▫️' },
    { value: ':large_orange_diamond:', label: ':large_orange_diamond:', image: '', unicode: '🔶' },
    { value: ':large_blue_diamond:', label: ':large_blue_diamond:', image: '', unicode: '🔷' },
    { value: ':small_orange_diamond:', label: ':small_orange_diamond:', image: '', unicode: '🔸' },
    { value: ':small_blue_diamond:', label: ':small_blue_diamond:', image: '', unicode: '🔹' },
    { value: ':small_red_triangle:', label: ':small_red_triangle:', image: '', unicode: '🔺' },
    { value: ':small_red_triangle_down:', label: ':small_red_triangle_down:', image: '', unicode: '🔻' },
    { value: ':diamond_shape_with_a_dot_inside:', label: ':diamond_shape_with_a_dot_inside:', image: '', unicode: '💠' },
    { value: ':radio_button:', label: ':radio_button:', image: '', unicode: '🔘' },
    { value: ':white_square_button:', label: ':white_square_button:', image: '', unicode: '🔳' },
    { value: ':black_square_button:', label: ':black_square_button:', image: '', unicode: '🔲' },
    { value: ':checkered_flag:', label: ':checkered_flag:', image: '', unicode: '🏁' },
    { value: ':triangular_flag_on_post:', label: ':triangular_flag_on_post:', image: '', unicode: '🚩' },
    { value: ':crossed_flags:', label: ':crossed_flags:', image: '', unicode: '🎌' },
    { value: ':flag_black:', label: ':flag_black:', image: '', unicode: '🏴' },
    { value: ':flag_white:', label: ':flag_white:', image: '', unicode: '🏳️' },
    { value: ':rainbow_flag:', label: ':rainbow_flag:', image: '', unicode: '🏳️‍🌈' },
    { value: ':gay_pride_flag:', label: ':gay_pride_flag:', image: '', unicode: '🏳️‍🌈' },
    { value: ':transgender_flag:', label: ':transgender_flag:', image: '', unicode: '🏳️‍⚧️' },
    { value: ':pirate_flag:', label: ':pirate_flag:', image: '', unicode: '🏴‍☠️' },
    { value: ':flag_ac:', label: ':flag_ac:', image: '', unicode: '🇦🇨' },
    { value: ':flag_ad:', label: ':flag_ad:', image: '', unicode: '🇦🇩' },
    { value: ':flag_ae:', label: ':flag_ae:', image: '', unicode: '🇦🇪' },
    { value: ':flag_af:', label: ':flag_af:', image: '', unicode: '🇦🇫' },
    { value: ':flag_ag:', label: ':flag_ag:', image: '', unicode: '🇦🇬' },
    { value: ':flag_ai:', label: ':flag_ai:', image: '', unicode: '🇦🇮' },
    { value: ':flag_al:', label: ':flag_al:', image: '', unicode: '🇦🇱' },
    { value: ':flag_am:', label: ':flag_am:', image: '', unicode: '🇦🇲' },
    { value: ':flag_ao:', label: ':flag_ao:', image: '', unicode: '🇦🇴' },
    { value: ':flag_aq:', label: ':flag_aq:', image: '', unicode: '🇦🇶' },
    { value: ':flag_ar:', label: ':flag_ar:', image: '', unicode: '🇦🇷' },
    { value: ':flag_as:', label: ':flag_as:', image: '', unicode: '🇦🇸' },
    { value: ':flag_at:', label: ':flag_at:', image: '', unicode: '🇦🇹' },
    { value: ':flag_au:', label: ':flag_au:', image: '', unicode: '🇦🇺' },
    { value: ':flag_aw:', label: ':flag_aw:', image: '', unicode: '🇦🇼' },
    { value: ':flag_ax:', label: ':flag_ax:', image: '', unicode: '🇦🇽' },
    { value: ':flag_az:', label: ':flag_az:', image: '', unicode: '🇦🇿' },
    { value: ':flag_ba:', label: ':flag_ba:', image: '', unicode: '🇧🇦' },
    { value: ':flag_bb:', label: ':flag_bb:', image: '', unicode: '🇧🇧' },
    { value: ':flag_bd:', label: ':flag_bd:', image: '', unicode: '🇧🇩' },
    { value: ':flag_be:', label: ':flag_be:', image: '', unicode: '🇧🇪' },
    { value: ':flag_bf:', label: ':flag_bf:', image: '', unicode: '🇧🇫' },
    { value: ':flag_bg:', label: ':flag_bg:', image: '', unicode: '🇧🇬' },
    { value: ':flag_bh:', label: ':flag_bh:', image: '', unicode: '🇧🇭' },
    { value: ':flag_bi:', label: ':flag_bi:', image: '', unicode: '🇧🇮' },
    { value: ':flag_bj:', label: ':flag_bj:', image: '', unicode: '🇧🇯' },
    { value: ':flag_bl:', label: ':flag_bl:', image: '', unicode: '🇧🇱' },
    { value: ':flag_bm:', label: ':flag_bm:', image: '', unicode: '🇧🇲' },
    { value: ':flag_bn:', label: ':flag_bn:', image: '', unicode: '🇧🇳' },
    { value: ':flag_bo:', label: ':flag_bo:', image: '', unicode: '🇧🇴' },
    { value: ':flag_bq:', label: ':flag_bq:', image: '', unicode: '🇧🇶' },
    { value: ':flag_br:', label: ':flag_br:', image: '', unicode: '🇧🇷' },
    { value: ':flag_bs:', label: ':flag_bs:', image: '', unicode: '🇧🇸' },
    { value: ':flag_bt:', label: ':flag_bt:', image: '', unicode: '🇧🇹' },
    { value: ':flag_bv:', label: ':flag_bv:', image: '', unicode: '🇧🇻' },
    { value: ':flag_bw:', label: ':flag_bw:', image: '', unicode: '🇧🇼' },
    { value: ':flag_by:', label: ':flag_by:', image: '', unicode: '🇧🇾' },
    { value: ':flag_bz:', label: ':flag_bz:', image: '', unicode: '🇧🇿' },
    { value: ':flag_ca:', label: ':flag_ca:', image: '', unicode: '🇨🇦' },
    { value: ':flag_cc:', label: ':flag_cc:', image: '', unicode: '🇨🇨' },
    { value: ':flag_cd:', label: ':flag_cd:', image: '', unicode: '🇨🇩' },
    { value: ':flag_cf:', label: ':flag_cf:', image: '', unicode: '🇨🇫' },
    { value: ':flag_cg:', label: ':flag_cg:', image: '', unicode: '🇨🇬' },
    { value: ':flag_ch:', label: ':flag_ch:', image: '', unicode: '🇨🇭' },
    { value: ':flag_ci:', label: ':flag_ci:', image: '', unicode: '🇨🇮' },
    { value: ':flag_ck:', label: ':flag_ck:', image: '', unicode: '🇨🇰' },
    { value: ':flag_cl:', label: ':flag_cl:', image: '', unicode: '🇨🇱' },
    { value: ':flag_cm:', label: ':flag_cm:', image: '', unicode: '🇨🇲' },
    { value: ':flag_cn:', label: ':flag_cn:', image: '', unicode: '🇨🇳' },
    { value: ':flag_co:', label: ':flag_co:', image: '', unicode: '🇨🇴' },
    { value: ':flag_cp:', label: ':flag_cp:', image: '', unicode: '🇨🇵' },
    { value: ':flag_cr:', label: ':flag_cr:', image: '', unicode: '🇨🇷' },
    { value: ':flag_cu:', label: ':flag_cu:', image: '', unicode: '🇨🇺' },
    { value: ':flag_cv:', label: ':flag_cv:', image: '', unicode: '🇨🇻' },
    { value: ':flag_cw:', label: ':flag_cw:', image: '', unicode: '🇨🇼' },
    { value: ':flag_cx:', label: ':flag_cx:', image: '', unicode: '🇨🇽' },
    { value: ':flag_cy:', label: ':flag_cy:', image: '', unicode: '🇨🇾' },
    { value: ':flag_cz:', label: ':flag_cz:', image: '', unicode: '🇨🇿' },
    { value: ':flag_de:', label: ':flag_de:', image: '', unicode: '🇩🇪' },
    { value: ':flag_dg:', label: ':flag_dg:', image: '', unicode: '🇩🇬' },
    { value: ':flag_dj:', label: ':flag_dj:', image: '', unicode: '🇩🇯' },
    { value: ':flag_dk:', label: ':flag_dk:', image: '', unicode: '🇩🇰' },
    { value: ':flag_dm:', label: ':flag_dm:', image: '', unicode: '🇩🇲' },
    { value: ':flag_do:', label: ':flag_do:', image: '', unicode: '🇩🇴' },
    { value: ':flag_dz:', label: ':flag_dz:', image: '', unicode: '🇩🇿' },
    { value: ':flag_ea:', label: ':flag_ea:', image: '', unicode: '🇪🇦' },
    { value: ':flag_ec:', label: ':flag_ec:', image: '', unicode: '🇪🇨' },
    { value: ':flag_ee:', label: ':flag_ee:', image: '', unicode: '🇪🇪' },
    { value: ':flag_eg:', label: ':flag_eg:', image: '', unicode: '🇪🇬' },
    { value: ':flag_eh:', label: ':flag_eh:', image: '', unicode: '🇪🇭' },
    { value: ':flag_er:', label: ':flag_er:', image: '', unicode: '🇪🇷' },
    { value: ':flag_es:', label: ':flag_es:', image: '', unicode: '🇪🇸' },
    { value: ':flag_et:', label: ':flag_et:', image: '', unicode: '🇪🇹' },
    { value: ':flag_eu:', label: ':flag_eu:', image: '', unicode: '🇪🇺' },
    { value: ':flag_fi:', label: ':flag_fi:', image: '', unicode: '🇫🇮' },
    { value: ':flag_fj:', label: ':flag_fj:', image: '', unicode: '🇫🇯' },
    { value: ':flag_fk:', label: ':flag_fk:', image: '', unicode: '🇫🇰' },
    { value: ':flag_fm:', label: ':flag_fm:', image: '', unicode: '🇫🇲' },
    { value: ':flag_fo:', label: ':flag_fo:', image: '', unicode: '🇫🇴' },
    { value: ':flag_fr:', label: ':flag_fr:', image: '', unicode: '🇫🇷' },
    { value: ':flag_ga:', label: ':flag_ga:', image: '', unicode: '🇬🇦' },
    { value: ':flag_gb:', label: ':flag_gb:', image: '', unicode: '🇬🇧' },
    { value: ':flag_gd:', label: ':flag_gd:', image: '', unicode: '🇬🇩' },
    { value: ':flag_ge:', label: ':flag_ge:', image: '', unicode: '🇬🇪' },
    { value: ':flag_gf:', label: ':flag_gf:', image: '', unicode: '🇬🇫' },
    { value: ':flag_gg:', label: ':flag_gg:', image: '', unicode: '🇬🇬' },
    { value: ':flag_gh:', label: ':flag_gh:', image: '', unicode: '🇬🇭' },
    { value: ':flag_gi:', label: ':flag_gi:', image: '', unicode: '🇬🇮' },
    { value: ':flag_gl:', label: ':flag_gl:', image: '', unicode: '🇬🇱' },
    { value: ':flag_gm:', label: ':flag_gm:', image: '', unicode: '🇬🇲' },
    { value: ':flag_gn:', label: ':flag_gn:', image: '', unicode: '🇬🇳' },
    { value: ':flag_gp:', label: ':flag_gp:', image: '', unicode: '🇬🇵' },
    { value: ':flag_gq:', label: ':flag_gq:', image: '', unicode: '🇬🇶' },
    { value: ':flag_gr:', label: ':flag_gr:', image: '', unicode: '🇬🇷' },
    { value: ':flag_gs:', label: ':flag_gs:', image: '', unicode: '🇬🇸' },
    { value: ':flag_gt:', label: ':flag_gt:', image: '', unicode: '🇬🇹' },
    { value: ':flag_gu:', label: ':flag_gu:', image: '', unicode: '🇬🇺' },
    { value: ':flag_gw:', label: ':flag_gw:', image: '', unicode: '🇬🇼' },
    { value: ':flag_gy:', label: ':flag_gy:', image: '', unicode: '🇬🇾' },
    { value: ':flag_hk:', label: ':flag_hk:', image: '', unicode: '🇭🇰' },
    { value: ':flag_hm:', label: ':flag_hm:', image: '', unicode: '🇭🇲' },
    { value: ':flag_hn:', label: ':flag_hn:', image: '', unicode: '🇭🇳' },
    { value: ':flag_hr:', label: ':flag_hr:', image: '', unicode: '🇭🇷' },
    { value: ':flag_ht:', label: ':flag_ht:', image: '', unicode: '🇭🇹' },
    { value: ':flag_hu:', label: ':flag_hu:', image: '', unicode: '🇭🇺' },
    { value: ':flag_ic:', label: ':flag_ic:', image: '', unicode: '🇮🇨' },
    { value: ':flag_id:', label: ':flag_id:', image: '', unicode: '🇮🇩' },
    { value: ':flag_ie:', label: ':flag_ie:', image: '', unicode: '🇮🇪' },
    { value: ':flag_il:', label: ':flag_il:', image: '', unicode: '🇮🇱' },
    { value: ':flag_im:', label: ':flag_im:', image: '', unicode: '🇮🇲' },
    { value: ':flag_in:', label: ':flag_in:', image: '', unicode: '🇮🇳' },
    { value: ':flag_io:', label: ':flag_io:', image: '', unicode: '🇮🇴' },
    { value: ':flag_iq:', label: ':flag_iq:', image: '', unicode: '🇮🇶' },
    { value: ':flag_ir:', label: ':flag_ir:', image: '', unicode: '🇮🇷' },
    { value: ':flag_is:', label: ':flag_is:', image: '', unicode: '🇮🇸' },
    { value: ':flag_it:', label: ':flag_it:', image: '', unicode: '🇮🇹' },
    { value: ':flag_je:', label: ':flag_je:', image: '', unicode: '🇯🇪' },
    { value: ':flag_jm:', label: ':flag_jm:', image: '', unicode: '🇯🇲' },
    { value: ':flag_jo:', label: ':flag_jo:', image: '', unicode: '🇯🇴' },
    { value: ':flag_jp:', label: ':flag_jp:', image: '', unicode: '🇯🇵' },
    { value: ':flag_ke:', label: ':flag_ke:', image: '', unicode: '🇰🇪' },
    { value: ':flag_kg:', label: ':flag_kg:', image: '', unicode: '🇰🇬' },
    { value: ':flag_kh:', label: ':flag_kh:', image: '', unicode: '🇰🇭' },
    { value: ':flag_ki:', label: ':flag_ki:', image: '', unicode: '🇰🇮' },
    { value: ':flag_km:', label: ':flag_km:', image: '', unicode: '🇰🇲' },
    { value: ':flag_kn:', label: ':flag_kn:', image: '', unicode: '🇰🇳' },
    { value: ':flag_kp:', label: ':flag_kp:', image: '', unicode: '🇰🇵' },
    { value: ':flag_kr:', label: ':flag_kr:', image: '', unicode: '🇰🇷' },
    { value: ':flag_kw:', label: ':flag_kw:', image: '', unicode: '🇰🇼' },
    { value: ':flag_ky:', label: ':flag_ky:', image: '', unicode: '🇰🇾' },
    { value: ':flag_kz:', label: ':flag_kz:', image: '', unicode: '🇰🇿' },
    { value: ':flag_la:', label: ':flag_la:', image: '', unicode: '🇱🇦' },
    { value: ':flag_lb:', label: ':flag_lb:', image: '', unicode: '🇱🇧' },
    { value: ':flag_lc:', label: ':flag_lc:', image: '', unicode: '🇱🇨' },
    { value: ':flag_li:', label: ':flag_li:', image: '', unicode: '🇱🇮' },
    { value: ':flag_lk:', label: ':flag_lk:', image: '', unicode: '🇱🇰' },
    { value: ':flag_lr:', label: ':flag_lr:', image: '', unicode: '🇱🇷' },
    { value: ':flag_ls:', label: ':flag_ls:', image: '', unicode: '🇱🇸' },
    { value: ':flag_lt:', label: ':flag_lt:', image: '', unicode: '🇱🇹' },
    { value: ':flag_lu:', label: ':flag_lu:', image: '', unicode: '🇱🇺' },
    { value: ':flag_lv:', label: ':flag_lv:', image: '', unicode: '🇱🇻' },
    { value: ':flag_ly:', label: ':flag_ly:', image: '', unicode: '🇱🇾' },
    { value: ':flag_ma:', label: ':flag_ma:', image: '', unicode: '🇲🇦' },
    { value: ':flag_mc:', label: ':flag_mc:', image: '', unicode: '🇲🇨' },
    { value: ':flag_md:', label: ':flag_md:', image: '', unicode: '🇲🇩' },
    { value: ':flag_me:', label: ':flag_me:', image: '', unicode: '🇲🇪' },
    { value: ':flag_mf:', label: ':flag_mf:', image: '', unicode: '🇲🇫' },
    { value: ':flag_mg:', label: ':flag_mg:', image: '', unicode: '🇲🇬' },
    { value: ':flag_mh:', label: ':flag_mh:', image: '', unicode: '🇲🇭' },
    { value: ':flag_mk:', label: ':flag_mk:', image: '', unicode: '🇲🇰' },
    { value: ':flag_ml:', label: ':flag_ml:', image: '', unicode: '🇲🇱' },
    { value: ':flag_mm:', label: ':flag_mm:', image: '', unicode: '🇲🇲' },
    { value: ':flag_mn:', label: ':flag_mn:', image: '', unicode: '🇲🇳' },
    { value: ':flag_mo:', label: ':flag_mo:', image: '', unicode: '🇲🇴' },
    { value: ':flag_mp:', label: ':flag_mp:', image: '', unicode: '🇲🇵' },
    { value: ':flag_mq:', label: ':flag_mq:', image: '', unicode: '🇲🇶' },
    { value: ':flag_mr:', label: ':flag_mr:', image: '', unicode: '🇲🇷' },
    { value: ':flag_ms:', label: ':flag_ms:', image: '', unicode: '🇲🇸' },
    { value: ':flag_mt:', label: ':flag_mt:', image: '', unicode: '🇲🇹' },
    { value: ':flag_mu:', label: ':flag_mu:', image: '', unicode: '🇲🇺' },
    { value: ':flag_mv:', label: ':flag_mv:', image: '', unicode: '🇲🇻' },
    { value: ':flag_mw:', label: ':flag_mw:', image: '', unicode: '🇲🇼' },
    { value: ':flag_mx:', label: ':flag_mx:', image: '', unicode: '🇲🇽' },
    { value: ':flag_my:', label: ':flag_my:', image: '', unicode: '🇲🇾' },
    { value: ':flag_mz:', label: ':flag_mz:', image: '', unicode: '🇲🇿' },
    { value: ':flag_na:', label: ':flag_na:', image: '', unicode: '🇳🇦' },
    { value: ':flag_nc:', label: ':flag_nc:', image: '', unicode: '🇳🇨' },
    { value: ':flag_ne:', label: ':flag_ne:', image: '', unicode: '🇳🇪' },
    { value: ':flag_nf:', label: ':flag_nf:', image: '', unicode: '🇳🇫' },
    { value: ':flag_ng:', label: ':flag_ng:', image: '', unicode: '🇳🇬' },
    { value: ':flag_ni:', label: ':flag_ni:', image: '', unicode: '🇳🇮' },
    { value: ':flag_nl:', label: ':flag_nl:', image: '', unicode: '🇳🇱' },
    { value: ':flag_no:', label: ':flag_no:', image: '', unicode: '🇳🇴' },
    { value: ':flag_np:', label: ':flag_np:', image: '', unicode: '🇳🇵' },
    { value: ':flag_nr:', label: ':flag_nr:', image: '', unicode: '🇳🇷' },
    { value: ':flag_nu:', label: ':flag_nu:', image: '', unicode: '🇳🇺' },
    { value: ':flag_nz:', label: ':flag_nz:', image: '', unicode: '🇳🇿' },
    { value: ':flag_om:', label: ':flag_om:', image: '', unicode: '🇴🇲' },
    { value: ':flag_pa:', label: ':flag_pa:', image: '', unicode: '🇵🇦' },
    { value: ':flag_pe:', label: ':flag_pe:', image: '', unicode: '🇵🇪' },
    { value: ':flag_pf:', label: ':flag_pf:', image: '', unicode: '🇵🇫' },
    { value: ':flag_pg:', label: ':flag_pg:', image: '', unicode: '🇵🇬' },
    { value: ':flag_ph:', label: ':flag_ph:', image: '', unicode: '🇵🇭' },
    { value: ':flag_pk:', label: ':flag_pk:', image: '', unicode: '🇵🇰' },
    { value: ':flag_pl:', label: ':flag_pl:', image: '', unicode: '🇵🇱' },
    { value: ':flag_pm:', label: ':flag_pm:', image: '', unicode: '🇵🇲' },
    { value: ':flag_pn:', label: ':flag_pn:', image: '', unicode: '🇵🇳' },
    { value: ':flag_pr:', label: ':flag_pr:', image: '', unicode: '🇵🇷' },
    { value: ':flag_ps:', label: ':flag_ps:', image: '', unicode: '🇵🇸' },
    { value: ':flag_pt:', label: ':flag_pt:', image: '', unicode: '🇵🇹' },
    { value: ':flag_pw:', label: ':flag_pw:', image: '', unicode: '🇵🇼' },
    { value: ':flag_py:', label: ':flag_py:', image: '', unicode: '🇵🇾' },
    { value: ':flag_qa:', label: ':flag_qa:', image: '', unicode: '🇶🇦' },
    { value: ':flag_re:', label: ':flag_re:', image: '', unicode: '🇷🇪' },
    { value: ':flag_ro:', label: ':flag_ro:', image: '', unicode: '🇷🇴' },
    { value: ':flag_rs:', label: ':flag_rs:', image: '', unicode: '🇷🇸' },
    { value: ':flag_ru:', label: ':flag_ru:', image: '', unicode: '🇷🇺' },
    { value: ':flag_rw:', label: ':flag_rw:', image: '', unicode: '🇷🇼' },
    { value: ':flag_sa:', label: ':flag_sa:', image: '', unicode: '🇸🇦' },
    { value: ':flag_sb:', label: ':flag_sb:', image: '', unicode: '🇸🇧' },
    { value: ':flag_sc:', label: ':flag_sc:', image: '', unicode: '🇸🇨' },
    { value: ':flag_sd:', label: ':flag_sd:', image: '', unicode: '🇸🇩' },
    { value: ':flag_se:', label: ':flag_se:', image: '', unicode: '🇸🇪' },
    { value: ':flag_sg:', label: ':flag_sg:', image: '', unicode: '🇸🇬' },
    { value: ':flag_sh:', label: ':flag_sh:', image: '', unicode: '🇸🇭' },
    { value: ':flag_si:', label: ':flag_si:', image: '', unicode: '🇸🇮' },
    { value: ':flag_sj:', label: ':flag_sj:', image: '', unicode: '🇸🇯' },
    { value: ':flag_sk:', label: ':flag_sk:', image: '', unicode: '🇸🇰' },
    { value: ':flag_sl:', label: ':flag_sl:', image: '', unicode: '🇸🇱' },
    { value: ':flag_sm:', label: ':flag_sm:', image: '', unicode: '🇸🇲' },
    { value: ':flag_sn:', label: ':flag_sn:', image: '', unicode: '🇸🇳' },
    { value: ':flag_so:', label: ':flag_so:', image: '', unicode: '🇸🇴' },
    { value: ':flag_sr:', label: ':flag_sr:', image: '', unicode: '🇸🇷' },
    { value: ':flag_ss:', label: ':flag_ss:', image: '', unicode: '🇸🇸' },
    { value: ':flag_st:', label: ':flag_st:', image: '', unicode: '🇸🇹' },
    { value: ':flag_sv:', label: ':flag_sv:', image: '', unicode: '🇸🇻' },
    { value: ':flag_sx:', label: ':flag_sx:', image: '', unicode: '🇸🇽' },
    { value: ':flag_sy:', label: ':flag_sy:', image: '', unicode: '🇸🇾' },
    { value: ':flag_sz:', label: ':flag_sz:', image: '', unicode: '🇸🇿' },
    { value: ':flag_ta:', label: ':flag_ta:', image: '', unicode: '🇹🇦' },
    { value: ':flag_tc:', label: ':flag_tc:', image: '', unicode: '🇹🇨' },
    { value: ':flag_td:', label: ':flag_td:', image: '', unicode: '🇹🇩' },
    { value: ':flag_tf:', label: ':flag_tf:', image: '', unicode: '🇹🇫' },
    { value: ':flag_tg:', label: ':flag_tg:', image: '', unicode: '🇹🇬' },
    { value: ':flag_th:', label: ':flag_th:', image: '', unicode: '🇹🇭' },
    { value: ':flag_tj:', label: ':flag_tj:', image: '', unicode: '🇹🇯' },
    { value: ':flag_tk:', label: ':flag_tk:', image: '', unicode: '🇹🇰' },
    { value: ':flag_tl:', label: ':flag_tl:', image: '', unicode: '🇹🇱' },
    { value: ':flag_tm:', label: ':flag_tm:', image: '', unicode: '🇹🇲' },
    { value: ':flag_tn:', label: ':flag_tn:', image: '', unicode: '🇹🇳' },
    { value: ':flag_to:', label: ':flag_to:', image: '', unicode: '🇹🇴' },
    { value: ':flag_tr:', label: ':flag_tr:', image: '', unicode: '🇹🇷' },
    { value: ':flag_tt:', label: ':flag_tt:', image: '', unicode: '🇹🇹' },
    { value: ':flag_tv:', label: ':flag_tv:', image: '', unicode: '🇹🇻' },
    { value: ':flag_tw:', label: ':flag_tw:', image: '', unicode: '🇹🇼' },
    { value: ':flag_tz:', label: ':flag_tz:', image: '', unicode: '🇹🇿' },
    { value: ':flag_ua:', label: ':flag_ua:', image: '', unicode: '🇺🇦' },
    { value: ':flag_ug:', label: ':flag_ug:', image: '', unicode: '🇺🇬' },
    { value: ':flag_um:', label: ':flag_um:', image: '', unicode: '🇺🇲' },
    { value: ':united_nations:', label: ':united_nations:', image: '', unicode: '🇺🇳' },
    { value: ':flag_us:', label: ':flag_us:', image: '', unicode: '🇺🇸' },
    { value: ':flag_uy:', label: ':flag_uy:', image: '', unicode: '🇺🇾' },
    { value: ':flag_uz:', label: ':flag_uz:', image: '', unicode: '🇺🇿' },
    { value: ':flag_va:', label: ':flag_va:', image: '', unicode: '🇻🇦' },
    { value: ':flag_vc:', label: ':flag_vc:', image: '', unicode: '🇻🇨' },
    { value: ':flag_ve:', label: ':flag_ve:', image: '', unicode: '🇻🇪' },
    { value: ':flag_vg:', label: ':flag_vg:', image: '', unicode: '🇻🇬' },
    { value: ':flag_vi:', label: ':flag_vi:', image: '', unicode: '🇻🇮' },
    { value: ':flag_vn:', label: ':flag_vn:', image: '', unicode: '🇻🇳' },
    { value: ':flag_vu:', label: ':flag_vu:', image: '', unicode: '🇻🇺' },
    { value: ':flag_wf:', label: ':flag_wf:', image: '', unicode: '🇼🇫' },
    { value: ':flag_ws:', label: ':flag_ws:', image: '', unicode: '🇼🇸' },
    { value: ':flag_xk:', label: ':flag_xk:', image: '', unicode: '🇽🇰' },
    { value: ':flag_ye:', label: ':flag_ye:', image: '', unicode: '🇾🇪' },
    { value: ':flag_yt:', label: ':flag_yt:', image: '', unicode: '🇾🇹' },
    { value: ':flag_za:', label: ':flag_za:', image: '', unicode: '🇿🇦' },
    { value: ':flag_zm:', label: ':flag_zm:', image: '', unicode: '🇿🇲' },
    { value: ':flag_zw:', label: ':flag_zw:', image: '', unicode: '🇿🇼' },
    { value: ':england:', label: ':england:', image: '', unicode: '🏴󠁧󠁢󠁥󠁮󠁧󠁿' },
    { value: ':scotland:', label: ':scotland:', image: '', unicode: '🏴󠁧󠁢󠁳󠁣󠁴󠁿' },
    { value: ':wales:', label: ':wales:', image: '', unicode: '🏴󠁧󠁢󠁷󠁬󠁳󠁿' },
];

module.exports = emojis;