<template>
  <div class="landing-page">
    <div class="container frosted-glass">
      <h1>Welcome to ServerQuery</h1>
      <p>A highly customizable game server query bot for Discord</p>
      <button class="btn-login" @click="login">{{ isLoggingIn ? 'Logging in...' : 'Login' }}</button>
    </div>
  </div>
</template>

<script>
export default {
    name: 'LandingPage',
    data() {
      return {
        isLoggingIn: false,
        isImageLoaded: false,
      };
    },
    methods: {
        login() {
            if (this.isLoggingIn) {
              return;
            }
        
            this.isLoggingIn = true;
        
            window.location.href = `${process.env.VUE_APP_BACKEND_URL}login`;
        }
    }
}
</script>

<style scoped>
.landing-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: white;
}

.container {
  position: relative;
  z-index: 2;
  text-align: center;
}

h1 {
  font-size: 3em;
  margin-bottom: 0.5em;
  color: white;
  text-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

p {
  font-size: 1.2em;
  margin-bottom: 1.5em;
  color: #ddd;
}

.frosted-glass {
  background: rgba(28, 37, 54, 0.1);
  padding: 40px;
  border-radius: 10px;
  backdrop-filter: blur(15px);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.btn-login {
    padding: 15px 30px;
    background-color: #7289DA;
    color: #FFF;
    border: none;
    text-decoration: none;
    border-radius: 4px;
    font-size: 18px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.btn-login:hover {
    background-color: #677BC4;
    transform: scale(1.05);
}

.btn-login:disabled {
    background-color: #999;
    cursor: not-allowed;
}
</style>
