<template>
    <div id="app">
        <div class="app-container">
            <div class="overlay"></div>
            <div class="sidenav">
                <SideNav class="SideNav" />
            </div>
            <div class="content-container">
                <router-view class="router-view" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

// Components
import SideNav from './components/SideNav.vue';

export default {
    name: 'App',
    data() {
        return {
            isLoading: true,
        };
    },
    components: {
        SideNav,
    },
    computed: mapState({
        authenticated: state => state.authenticated,
        user: state => state.user,
        guilds: state => state.guilds,

    }),
    async created() {
        await this.$store.dispatch('checkAuthentication');

        if (this.authenticated) {
            if (!this.user.global_name || !this.user.id || !this.user.banner || !this.user.banner_color || !this.user.avatar || !this.user.botInGuild) {
                await this.getUserData();
            }
            if (!this.guilds || this.guilds.length === 0) {
                await this.getUserGuilds();
            } else {
                for (let guild of this.guilds) {
                    if (guild.botPresent) {
                        await this.getGuildInfo(guild.id);
                    } else {
                        continue;
                    }
                }
            }
            for (let guild of this.guilds) {
                if (guild.id) {
                    this.getBotPresence(guild.id);
                    this.generateGuildIcon(guild);
                } else {
                    continue;
                }
            }
        }

    },
    methods: {
        ...mapActions([
            'getUserData',
            'getUserGuilds',
            'getGuildInfo',
            'getBotPresence',
            'generateGuildIcon',
            'logOut',
        ]),
    },
};
</script>

<style>
body {
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #161b27;

}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background-color: #161b27;
}

::-webkit-scrollbar-thumb {
    background-color: #2a3652;
    border-radius: 5px;
}

#app,
.app-container {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-flow: row;
    overflow: auto;
    margin: 0;
    padding: 0;

    /* Hybrid Background Setup */
    background: 
        /* Top layer (Geometric or Polygon) */
        /* url('https://cdn.pixabay.com/photo/2016/06/02/02/33/triangles-1430105_1280.png') no-repeat center center fixed,
        /* Middle layer (Nebula or Charcoal) */
        url('./assets/2400-charcoal-minimalist-wallpaper-high-quality-4k-hdr.jpg') no-repeat center center fixed,
        /* Bottom layer (Gradient or Dark overlay) */
        linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.0));
    
    background-size: cover;
    background-blend-mode: overlay, normal;
    position: relative;
}

.background-blur {
    filter: blur(2px); /* Adjust blur effect on lower layers */
}

.background-dark {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.9));
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(2px);
  z-index: 1;
}

.sidenav {
    overflow: hidden;
    box-sizing: border-box;
    z-index: 2;
}

.content-container {
    flex: calc(100% - 82px);
    box-sizing: border-box;
    overflow: hidden;
    z-index: 2;
}

.router-view {
    display: block;
    padding: 15px;
    width: 100% !important;
    z-index: 2;
}
</style>